<template>
  <router-link
    class="curator-author"
    :class="{'curator-author__disable': !author?.active}"
    v-if="author"
    :to="{name: toAuthor, params: { id: author.id, back: toBack}}"
  >
    <div class="curator-author__top">
      <div class="curator-author__picture">
        <div class="curator-author__plug" v-if="!author.avatarCurrent">
          <svg viewBox="0 0 35 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.8423 28.2997L22.5668 23.4655L26.1349 18.8408C26.791 18.0024 27.1804 16.9783 27.1804 15.8795V8.90569C27.1804 4.50534 22.8559 0.938477 17.5208 0.938477C12.1857 0.938477 7.86115 4.50534 7.86115 8.90569V15.8795C7.86115 16.9783 8.2505 18.0024 8.92222 18.8623L8.90662 18.8402L12.4747 23.4649L3.19926 28.2991C1.52443 29.1822 0.430664 30.7077 0.430664 32.4439V37.7531L34.6109 37.7096V32.4439C34.6109 30.7077 33.5164 29.1822 31.8661 28.3108L31.8423 28.2991V28.2997Z"/>
          </svg>
        </div>
        <img
          :src="author.avatarCurrent"
          :alt="author.fullName"
          v-else
        >
      </div>
      <div class="curator-author__content">
        <h6 class="curator-author__name" v-if="author.fullName">{{ author.fullName }}</h6>
        <div class="curator-author__description"
             v-if="author.description"
             v-html="descriptionFormatted"
        />
      </div>
    </div>
    <div class="curator-author__bottom" v-if="author.studentCount">
      <div class="curator-author__stat">
        <div class="curator-author__stat-count">{{ author.studentCount }}</div>
        <div class="curator-author__stat-text">Количество учеников</div>
      </div>
      <div class="curator-author__stat" v-if="author.mentorshipCount">
        <div class="curator-author__stat-count">{{ author.mentorshipCount }}</div>
        <div class="curator-author__stat-text">Количество менторшип</div>
      </div>
    </div>
  </router-link>
</template>

<script>
import Author from '@/assets/js/Author.class'
import {formatted} from '@/assets/js/helpers'

export default {
  name: 'CuratorAuthor',
  props: {
    author: {
      type: Author,
      required: true,
    },
    toAuthor: {
      type: String,
      default: 'item-author',
    },
    back: {
      default: null
    }
  },
  computed: {
    toBack() {
      return this.back || this.$route
    },
    descriptionFormatted(){
      return formatted(this.author?.description || '')
    }
  },
}
</script>

<style lang="scss" scoped>
.curator-author {
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  cursor: pointer;
  text-decoration: none;
  width: 371px;
  border-radius: 10px;
  color: inherit;
  box-sizing: border-box;
  box-shadow: 0 9px 16px 10px rgba(61, 61, 61, 0.08);

  &:hover{
    color: inherit;
  }
  &__top{
    padding: 23px 23px 11px;
  }
  &__bottom{
    padding: 18px 30px 24px;
    border-top: 3px solid var(--base-card-bg);
    display: flex;
    margin: 14px 20px 0;
  }
  &__stat{
    min-width: 100px;
    margin-right: 50px;
    line-height: 1.25;

    &-count{
      font-weight: 600;
      font-size: 2rem;
      margin-bottom: 4px;
    }
    &-text{
      font-size: 0.875rem;
      opacity: .7;
    }
  }
  &__disable{
    opacity: 0.3;
  }
  &__picture {
    flex: none;
    width: 6.2rem;
    height: 6.2rem;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    margin-bottom: 1.5rem;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      z-index: 2;
    }
  }
  &__plug {
    width: 100%;
    height: 100%;
    border: 0.0625rem solid var(--color);
    border-radius: 50%;
    position: relative;
    z-index: 1;
    svg {
      display: block;
      width: 1rem;
      height: 1rem;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      path {
        fill: var(--color);
      }
    }
  }
  &__content {
    width: 100%;
  }
  &__name {
    font-weight: 600;
    line-height: 120%;
    font-size: 1.5rem;
    margin: 0 0 6px;
  }
  &__description {
    line-height: 1.4;
    font-size: 1.125rem;
    margin: 0;
  }

  @include media-breakpoint-down(sm) {
    width: 300px;
    &__top{
      padding: 23px 20px 10px;
    }
    &__bottom{
      padding: 16px 0 18px;
      margin: 6px 20px 0;
    }
    &__picture {
      width: 74px;
      height: 74px;
      margin-bottom: 1.1rem;
    }
    &__name {
      font-size: 1.125rem;
      margin: 0 0 6px;
    }
    &__description {
      font-size: 0.8125rem;
    }
    &__stat{
      width: 60px;
      margin-right: 10px;
      line-height: 1.25;

      &-count{
        font-size: 1.5rem;
        margin-bottom: 4px;
      }
      &-text{
        font-size: 0.6875rem;
        opacity: .7;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    width: 248px;
    &__top{
      padding: 18px 20px 10px;
    }
    &__bottom{
      padding: 16px 0 18px;
    }
    &__picture {
      width: 64px;
      height: 64px;
      margin-bottom: 17px;
    }
  }
}
.c-dark-theme {
  .curator-author {
    background: var(--subscribe-card-bg);
    box-shadow: none;
  }
}
</style>
