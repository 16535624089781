var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "CModal",
        {
          attrs: {
            show: _vm.showModal,
            centered: "",
            scrollable: "",
            closeOnBackdrop: false,
            size: "lg",
          },
          on: {
            "update:show": function ($event) {
              _vm.showModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "header-wrapper",
              fn: function () {
                return [
                  _c("div", { staticClass: "modal-title__wrap" }, [
                    _c(
                      "button",
                      {
                        staticClass: "modal-title__close",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            _vm.showModal = false
                          },
                        },
                      },
                      [
                        _c("CIcon", {
                          staticClass: "modal-title__close-icon",
                          attrs: { name: "cil-x" },
                        }),
                      ],
                      1
                    ),
                    _c("h5", { staticClass: "modal-title" }, [
                      _c("span", { staticClass: "modal-title__icon" }, [
                        _vm._v("🔥"),
                      ]),
                      _c("span", [
                        _vm._v(
                          "Специальное предложение на обучение от Cryptovizor!"
                        ),
                      ]),
                      _c("span", { staticClass: "modal-title__icon" }, [
                        _vm._v("🔥"),
                      ]),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer-wrapper",
              fn: function () {
                return [_c("div")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.prebuyDataItem
            ? [
                _c("div", { staticClass: "card-info" }, [
                  _c("div", { staticClass: "card-info__title-wrap" }, [
                    _vm.prebuyDataItem.tag
                      ? _c("div", { staticClass: "card-info__tag" }, [
                          _vm._v(" " + _vm._s(_vm.prebuyDataItem.tag) + " "),
                        ])
                      : _vm._e(),
                    _vm.prebuyDataItem.title
                      ? _c("div", { staticClass: "card-info__title" }, [
                          _vm._v(" " + _vm._s(_vm.prebuyDataItem.title) + " "),
                        ])
                      : _vm._e(),
                    _vm.prebuyDataItem.text
                      ? _c("div", { staticClass: "card-info__text" }, [
                          _vm._v(" " + _vm._s(_vm.prebuyDataItem.text) + " "),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-info__footer" },
                    [
                      _c("div", { staticClass: "card-info__price-wrap" }, [
                        _vm.prebuyDataItem.price
                          ? _c("div", { staticClass: "card-info__price" }, [
                              _vm._v(
                                " " + _vm._s(_vm.prebuyDataItem.price) + " "
                              ),
                            ])
                          : _vm._e(),
                        _vm.prebuyDataItem.priceOld
                          ? _c("div", { staticClass: "card-info__price-old" }, [
                              _vm._v(
                                " " + _vm._s(_vm.prebuyDataItem.priceOld) + " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "CButton",
                        {
                          staticClass: "card-info__btn",
                          attrs: { color: "primary", size: "lg" },
                          on: { click: _vm.toggleShowExplanation },
                        },
                        [_vm._v(" Перейти к оплате ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.prebuyDataItem.list?.length
                  ? _c("div", { staticClass: "card-desc" }, [
                      _c("div", { staticClass: "card-desc__title" }, [
                        _vm._v(" Почему это предложение идеально для вас? "),
                      ]),
                      _c(
                        "div",
                        { staticClass: "card-desc__inner" },
                        _vm._l(_vm.prebuyDataItem.list, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "card-desc__item",
                              class: { "_two-cols": item.twoCols },
                            },
                            [
                              item.title
                                ? _c("div", {
                                    staticClass: "card-desc__item-title",
                                    domProps: { innerHTML: _vm._s(item.title) },
                                  })
                                : _vm._e(),
                              item.text
                                ? _c("div", {
                                    staticClass: "card-desc__item-text",
                                    domProps: { innerHTML: _vm._s(item.text) },
                                  })
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "bottom-info" }, [
                  _c("span", { staticClass: "bottom-info__icon" }, [
                    _vm._v("⭐"),
                  ]),
                  _c("span", [
                    _vm._v(
                      "100% средств собранных с продажи обучения будут направлены на финансирование разработки Cryptovizor"
                    ),
                  ]),
                  _c("span", { staticClass: "bottom-info__icon" }, [
                    _vm._v("⭐"),
                  ]),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.showExplanation
        ? _c("ChangeTariff", {
            attrs: {
              "show-explanation": _vm.showExplanation,
              "target-tariff": _vm.targetTariff,
            },
            on: {
              updateShow: function ($event) {
                _vm.showExplanation = $event
              },
              errorPopup: _vm.handleError,
              tariffChanged: _vm.successClose,
            },
          })
        : _vm._e(),
      _c(
        "CModal",
        {
          attrs: {
            title: _vm.$t("lk.subscribe.modal.tariffChange"),
            centered: "",
            show: _vm.showPopupError,
          },
          on: {
            "update:show": function ($event) {
              _vm.showPopupError = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "CButton",
                        {
                          attrs: { color: "dark" },
                          on: {
                            click: function ($event) {
                              _vm.showPopupError = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("lk.subscribe.modal.cancel")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.paymentUrl
            ? [
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("lk.subscribe.modal.pageNoOpen"))),
                ]),
                _c(
                  "CLink",
                  {
                    staticClass: "text-primary",
                    attrs: { target: "_blank", href: _vm.paymentUrl },
                    on: {
                      click: function ($event) {
                        _vm.showPopupError = false
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.paymentUrl) + " ")]
                ),
              ]
            : [_c("p", [_vm._v(_vm._s(_vm.errorMessage))])],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }