<template>
  <div>
    <CModal
      :show.sync="showModal"
      centered
      scrollable
      :closeOnBackdrop="false"
      size="lg"
    >
      <template #header-wrapper>
        <div class="modal-title__wrap">
          <button
            type="button"
            class="modal-title__close"
            @click="showModal = false"
          >
            <CIcon
              name="cil-x"
              class="modal-title__close-icon"
            />
          </button>

          <h5 class="modal-title">
            <span class="modal-title__icon">🔥</span>
            <span>Специальное предложение на&nbsp;обучение от Cryptovizor!</span>
            <span class="modal-title__icon">🔥</span>
          </h5>
        </div>
      </template>

      <template
        v-if="prebuyDataItem"
      >
        <div class="card-info">
          <div class="card-info__title-wrap">
            <div
              v-if="prebuyDataItem.tag"
              class="card-info__tag"
            >
              {{ prebuyDataItem.tag }}
            </div>

            <div
              v-if="prebuyDataItem.title"
              class="card-info__title"
            >
              {{ prebuyDataItem.title }}
            </div>

            <div
              v-if="prebuyDataItem.text"
              class="card-info__text"
            >
              {{ prebuyDataItem.text }}
            </div>
          </div>

          <div class="card-info__footer">
            <div class="card-info__price-wrap">
              <div
                v-if="prebuyDataItem.price"
                class="card-info__price"
              >
                {{ prebuyDataItem.price }}
              </div>

              <div
                v-if="prebuyDataItem.priceOld"
                class="card-info__price-old"
              >
                {{ prebuyDataItem.priceOld }}
              </div>
            </div>

            <CButton
              color="primary"
              size="lg"
              class="card-info__btn"
              @click="toggleShowExplanation"
            >
              Перейти к оплате
            </CButton>
          </div>
        </div>

        <div
          v-if="prebuyDataItem.list?.length"
          class="card-desc"
        >
          <div class="card-desc__title">
            Почему это предложение идеально для вас?
          </div>

          <div class="card-desc__inner">
            <div
              v-for="(item, index) in prebuyDataItem.list"
              :key="index"
              class="card-desc__item"
              :class="{ '_two-cols': item.twoCols }"
            >
              <div
                v-if="item.title"
                class="card-desc__item-title"
                v-html="item.title"
              />

              <div
                v-if="item.text"
                class="card-desc__item-text"
                v-html="item.text"
              />
            </div>
          </div>
        </div>

        <div class="bottom-info">
          <span class="bottom-info__icon">⭐</span>
          <span>100% средств собранных с продажи обучения будут направлены на финансирование разработки Cryptovizor</span>
          <span class="bottom-info__icon">⭐</span>
        </div>
      </template>

      <template #footer-wrapper>
        <div/>
      </template>
    </CModal>

    <ChangeTariff
      v-if="showExplanation"
      :show-explanation="showExplanation"
      :target-tariff="targetTariff"
      @updateShow="showExplanation = $event"
      @errorPopup="handleError"
      @tariffChanged="successClose"
    />

    <CModal
      :title="$t('lk.subscribe.modal.tariffChange')"
      centered
      :show.sync="showPopupError"
    >
      <template v-if="paymentUrl">
        <p>{{ $t("lk.subscribe.modal.pageNoOpen") }}</p>

        <CLink
          class="text-primary"
          target="_blank"
          :href="paymentUrl"
          @click="showPopupError = false"
        >
          {{ paymentUrl }}
        </CLink>
      </template>

      <template v-else>
        <p>{{ errorMessage }}</p>
      </template>

      <template v-slot:footer>
        <div class="w-100">
          <CButton color="dark" @click="showPopupError = false">
            {{ $t("lk.subscribe.modal.cancel") }}
          </CButton>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import {prebuyData} from "@/assets/data/prebuyData";
import ChangeTariff from "@/components/subscribe/ChangeTariff.vue";
import {mapActions} from "vuex";

export default {
  name: "PrebuyModal",
  components: {ChangeTariff},
  data() {
    return {
      showModal: false,
      prebuyDataItem: null,
      showExplanation: false,
      targetTariff: null,
      showPopupError: false,
      paymentUrl: "",
      errorMessage: "",
    }
  },
  created() {
    this.checkModalData()
  },
  methods: {
    ...mapActions({
      toggleShowLoginModal: 'user/toggleShowLoginModal',
    }),
    checkModalData() {
      const query = this.$route.query
      const prebuyQuary = query.prebuy

      if (prebuyQuary && !Array.isArray(prebuyQuary)) {
        this.prebuyDataItem = prebuyData[prebuyQuary]
        this.showModal = !!this.prebuyDataItem

        if (this.prebuyDataItem) {
          this.targetTariff = {
            isGroup: true,
            tariff_id: this.prebuyDataItem.id,
            title: this.prebuyDataItem.title,
          }
        }
      } else {
        this.prebuyDataItem = null
        this.targetTariff = null
        this.showModal = false
      }
    },
    successClose() {
      window.location.reload()
    },
    toggleShowExplanation() {
      if (this.$store.state.user.userData?.isGuest) {
        this.toggleShowLoginModal({show: true})
        return
      }

      this.showModal = false
      this.showExplanation = true
      this.removePrebuyFromQuery()
    },
    removePrebuyFromQuery() {
      const query = {...this.$route.query}
      const prebuyQuary = query.prebuy

      if (prebuyQuary) {
        delete query.prebuy
        this.$router.replace({query})
      }
    },
    handleError(error) {
      this.showPopupError = true;
      if (error.type === "block") {
        this.paymentUrl = error.message;
      } else {
        this.errorMessage = error.message;
      }
    },
  },
}
</script>

<style scoped lang="scss">
.modal-title {
  display: flex;
  text-align: center;
  color: #80B3F9;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.01em;

  @include media-breakpoint-up(md) {
    font-size: 29px;
    letter-spacing: normal;
  }

  @include media-breakpoint-up(xl) {
    font-size: 32px;
  }

  &__wrap {
    padding: 30px 10px 16px;

    @include media-breakpoint-up(md) {
      padding: 30px 30px 16px;
    }
  }

  &__icon {
    font-size: 16px;

    @include media-breakpoint-up(md) {
      font-size: 32px;
    }
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    height: 16px;
    background: none;
    border: none;
    color: #fff;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .2s;

    &:hover {
      opacity: .5;
    }
  }
}

:deep(.modal-body) {
  padding: 0 10px 30px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include media-breakpoint-up(md) {
    padding: 0 30px 30px;
  }
}

.card-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
  color: #FFF;
  border-radius: 3px;
  background: rgba(54, 134, 246, 0.20);
  padding: 16px;

  &__tag {
    border-radius: 53px;
    background: rgba(255, 255, 255, 0.10);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
    padding: 4px 8px;
    width: fit-content;
  }

  &__title {
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
    opacity: 0.7;

    @include media-breakpoint-up(xl) {
      max-width: 460px;
    }
  }

  &__title-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  &__price-wrap {
    text-align: center;
    display: flex;
    align-items: baseline;
    gap: 8px;
  }

  &__price {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__price-old {
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
    text-decoration-line: line-through;
    opacity: 0.5;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;
  }

  &__btn {
    width: 100%;
    font-size: 15px;
    min-height: 45px;
    border-radius: 3px;
  }
}

:deep(.modal-content) {
  border-radius: 3px;
  background: #24252F;

  @include media-breakpoint-up(md) {
    width: 545px;
  }

  @include media-breakpoint-up(xl) {
    width: 630px;
  }
}

.card-desc {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 3px;
  background: rgba(24, 25, 36, 0.30);

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__title {
    color: #80B3F9;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__item {
    color: #FFF;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    &._two-cols {
      @include media-breakpoint-up(md) {
        grid-column: span 2;
      }
    }

    &-title {
      font-size: 16px;
    }

    &-text {
      font-size: 14px;
      opacity: 0.5;

      :deep(p) {
        margin: 0;
      }
    }
  }
}

.bottom-info {
  display: flex;
  text-align: center;
  color: #80B3F9;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @include media-breakpoint-up(md) {
    font-size: 18px;
    letter-spacing: -0.01em;
  }

  @include media-breakpoint-up(xl) {
    font-size: 19px;
  }

  &__icon {
    font-size: 22px;
    line-height: 1;
  }
}
</style>
