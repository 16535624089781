var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "training-detail" },
    [
      _vm.loading
        ? _c("CSpinner", { attrs: { color: "primary", size: "lg" } })
        : _vm._e(),
      _vm.course
        ? _c(
            "div",
            { staticClass: "training-detail__content" },
            [
              _c(
                "div",
                { staticClass: "training-detail__picture" },
                [
                  _c(
                    "CButton",
                    {
                      staticClass: "cbtn",
                      attrs: { color: "primary", to: "/lk/subscribe?study=1" },
                    },
                    [
                      _c("CIcon", { attrs: { name: "cil-arrow-left" } }),
                      _vm._v(
                        " " + _vm._s(_vm.$t("lk.training.labelBackBtn")) + " "
                      ),
                    ],
                    1
                  ),
                  _vm.course.course_wide_photo
                    ? _c("img", {
                        attrs: {
                          src: `${_vm.baseUrl}${_vm.course.course_wide_photo}`,
                          alt: _vm.course.course_title,
                        },
                      })
                    : _c("img", {
                        attrs: {
                          src: `${_vm.BUILD_PUBLIC_PATH}/img/plugs/training.jpg`,
                          alt: _vm.course.course_title,
                        },
                      }),
                  _c(
                    "div",
                    { staticClass: "training-detail__picture-content" },
                    [
                      _vm.course.course_title
                        ? [
                            _c("h1", {
                              staticClass: "training-detail__title",
                              domProps: {
                                innerHTML: _vm._s(_vm.course.course_title),
                              },
                            }),
                          ]
                        : _vm._e(),
                      _vm.course?.authors?.length
                        ? [
                            _c("small-author", {
                              staticClass: "training-detail__author _big",
                              attrs: { author: _vm.course.authors[0] },
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.course.course_description
                ? _c("div", { staticClass: "training-detail__desc" }, [
                    _c("div", { staticClass: "training-detail__sub-head" }, [
                      _c(
                        "h3",
                        { staticClass: "training-detail__subtitle title" },
                        [_vm._v(_vm._s(_vm.$t("lk.training.descriptionTitle")))]
                      ),
                    ]),
                    _c("div", {
                      staticClass: "training-detail__desc-text",
                      domProps: {
                        innerHTML: _vm._s(_vm.course.course_description),
                      },
                    }),
                  ])
                : _vm._e(),
              _vm.course.links && _vm.course.links.length
                ? _c("div", { staticClass: "training-detail__links" }, [
                    _c("div", { staticClass: "training-detail__sub-head" }, [
                      _c(
                        "h3",
                        { staticClass: "training-detail__subtitle title" },
                        [_vm._v(_vm._s(_vm.$t("lk.training.linksTitle")))]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.course.links, function (linkItem, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass:
                              "col-lg-6 mb-2 training-detail__links-item",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "training-detail__links-link",
                                attrs: {
                                  href: linkItem.link,
                                  target: "_blank",
                                },
                              },
                              [
                                _c("CIcon", {
                                  staticClass: "mr-1 mb-1",
                                  attrs: { name: "cil-link", size: "sm" },
                                }),
                                _vm._v(" " + _vm._s(linkItem.title) + " "),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm.course.program
                ? _c(
                    "div",
                    {
                      staticClass: "training-detail__accordion",
                      class: { _active: _vm.activeProgram },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "training-detail__sub-head _accordion",
                          on: {
                            click: function ($event) {
                              return _vm.toggleAccordion()
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "training-detail__subarrow" },
                            [_vm._v(" ❭ ")]
                          ),
                          _c(
                            "h3",
                            { staticClass: "training-detail__subtitle title" },
                            [_vm._v(_vm._s(_vm.$t("lk.training.programTitle")))]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show-slide",
                              rawName: "v-show-slide:600",
                              value: _vm.activeProgram,
                              expression: "activeProgram",
                              arg: "600",
                            },
                          ],
                          staticClass: "training-detail__info",
                        },
                        [
                          _c("LessonAccordionComponent", {
                            attrs: { "accordion-data": _vm.course.program },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.course.curator_authors && _vm.course.curator_authors.length
                ? _c("div", { staticClass: "training-detail__curators" }, [
                    _c("div", { staticClass: "training-detail__sub-head" }, [
                      _c(
                        "h3",
                        { staticClass: "training-detail__subtitle title" },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("lk.training.curatorAuthorsTitle"))
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { ref: "swiper", staticClass: "swiper" }, [
                      _c(
                        "div",
                        { staticClass: "swiper-wrapper" },
                        _vm._l(_vm.course.curator_authors, function (item, i) {
                          return _c("CuratorAuthor", {
                            key: i,
                            staticClass:
                              "swiper-slide training-detail__curator-item",
                            attrs: { author: item },
                          })
                        }),
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.subscribeData.length
                ? _c(
                    "div",
                    { staticClass: "training-detail__tariffs" },
                    [
                      _c("div", { staticClass: "training-detail__sub-head" }, [
                        _c(
                          "h3",
                          { staticClass: "training-detail__subtitle title" },
                          [_vm._v(_vm._s(_vm.$t("lk.training.tariffsTitle")))]
                        ),
                      ]),
                      _vm.loadingTariffs || _vm.loadingEduTariffsAccess
                        ? _c("CSpinner", {
                            attrs: { color: "primary", size: "sm" },
                          })
                        : _c(
                            "div",
                            { staticClass: "training-detail__tariffs-list" },
                            _vm._l(_vm.subscribeData, function (item) {
                              return _c("subscribe-card", {
                                key: item.id,
                                staticClass: "_inner",
                                attrs: {
                                  "active-tariff-id": item.isTraining
                                    ? (_vm.course.isAccess &&
                                        (!_vm.course?.accessConditions ||
                                          _vm.course?.accessConditions
                                            .access) &&
                                        _vm.course.userTariff) ||
                                      ""
                                    : _vm.userTariffId,
                                  "active-tariff": item.isTraining
                                    ? (_vm.course.isAccess &&
                                        (!_vm.course?.accessConditions ||
                                          _vm.course?.accessConditions
                                            .access) &&
                                        (_vm.course.userTariff || "") ===
                                          item.id) ||
                                      false
                                    : _vm.userTariffActive,
                                  color: item.color,
                                  "to-date": item.isTraining
                                    ? null
                                    : _vm.getToDate,
                                  "tariff-id": item.id,
                                  title: item.name,
                                  description: item.description,
                                  secondDesc: item.secondDesc,
                                  "active-month": item.isTraining
                                    ? 0
                                    : _vm.activeMonth,
                                  "month-list": item.isTraining
                                    ? item.monthList
                                    : item.variants,
                                  "function-list": item.features,
                                  isTraining: item.isTraining,
                                  isIndependent: item.isIndependent || false,
                                  learnList: item.learnList,
                                  dailyProfit: item.dailyProfit,
                                  teacherName: item.teacherName,
                                  teacherPosition: item.teacherPosition,
                                  teacherInfo: item.teacherInfo,
                                  price: item.price,
                                  isSpecical: item.isSpecical,
                                  disabledPay: item.disabledPay,
                                  hideSelect: item.hideSelect,
                                  hasMentor: _vm.course.userHasMentor,
                                  currentCurator: _vm.course.userCurator,
                                  isFree: item.isFree,
                                  course: _vm.course,
                                  isAccessByConditions:
                                    item.isAccessByConditions,
                                },
                                on: {
                                  pay: _vm.prePay,
                                  updatePreview: _vm.updatePreview,
                                },
                              })
                            }),
                            1
                          ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showExplanation
                ? _c("ChangeTariff", {
                    attrs: {
                      "show-explanation": _vm.showExplanation,
                      "target-tariff": _vm.targetTariff,
                    },
                    on: {
                      firstBuyTraining: _vm.toggleShowCongratulate,
                      updateShow: _vm.updateShow,
                      errorPopup: _vm.handleError,
                      updatePreview: _vm.updatePreview,
                    },
                  })
                : _vm._e(),
              _c(
                "CModal",
                {
                  attrs: {
                    title: _vm.$t("lk.subscribe.modal.tariffChange"),
                    centered: "",
                    show: _vm.showPopupError,
                  },
                  on: {
                    "update:show": function ($event) {
                      _vm.showPopupError = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "footer",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "w-100" },
                              [
                                _c(
                                  "CButton",
                                  {
                                    attrs: { color: "dark" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showPopupError = false
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("lk.subscribe.modal.cancel")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1183420738
                  ),
                },
                [
                  _vm.paymentUrl
                    ? [
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.$t("lk.subscribe.modal.pageNoOpen"))
                          ),
                        ]),
                        _c(
                          "CLink",
                          {
                            staticClass: "text-primary",
                            attrs: { target: "_blank", href: _vm.paymentUrl },
                            on: {
                              click: function ($event) {
                                _vm.showPopupError = false
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.paymentUrl) + " ")]
                        ),
                      ]
                    : [_c("p", [_vm._v(_vm._s(_vm.errorMessage))])],
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c("PrebuyModal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }