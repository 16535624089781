var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c(
        "CCard",
        { staticClass: "shadow-none w-100 mb-0", style: _vm.borderStyle },
        [
          _c(
            "div",
            {
              staticClass:
                "card-body-custom flex-wrap d-flex justify-content-between",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "card-body-custom__inner flex-wrap d-flex flex-grow-1",
                },
                [
                  _c(
                    "div",
                    { staticClass: "base-info" },
                    [
                      _c(
                        "CBadge",
                        {
                          staticClass: "text-uppercase",
                          style: _vm.badgeStyle,
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isTariffActive
                                  ? _vm.$t("lk.subscribe.active")
                                  : _vm.$t("lk.subscribe.inactive")
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _vm.active && _vm.toDate
                        ? _c("span", {
                            staticClass: "ml-2",
                            domProps: { innerHTML: _vm._s(_vm.toDate) },
                          })
                        : _vm._e(),
                      _c(
                        "h2",
                        {
                          staticClass:
                            "title mt-2 mb-3 text-uppercase font-weight-bold",
                          style: _vm.titleStyle,
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.title[_vm.locale].toUpperCase()) +
                              " "
                          ),
                          _vm.isSpecical
                            ? _c(
                                "svg",
                                {
                                  staticClass: "specical__icon",
                                  attrs: {
                                    viewBox: "0 0 50 68",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d: "M20.0224 1.02662C20.6281 0.329655 21.5687 0.0233438 22.4687 0.229964C34.4982 2.99168 40.5786 16.1737 39.1487 27.9675C38.5928 32.5521 37.2196 36.1463 35.1974 39.0059C35.185 39.0234 35.1726 39.0408 35.1602 39.0582C35.6196 38.8324 36.0433 38.5888 36.4398 38.3315C38.4129 37.0509 39.8611 35.3608 41.7466 33.1602C41.9745 32.8942 42.2088 32.6208 42.4512 32.3397C42.9959 31.7081 43.8197 31.3897 44.6476 31.4908C45.4756 31.592 46.1985 32.0993 46.5751 32.8436C48.2336 36.1209 49.1668 39.8261 49.1668 43.742C49.1668 57.0889 38.347 67.9086 25.0002 67.9086C11.6533 67.9086 0.833496 57.0889 0.833496 43.742C0.833496 35.2851 5.17918 27.8449 11.7479 23.53L11.9731 23.3031C12.0887 23.1865 12.2156 23.0816 12.3519 22.9899C15.2381 21.0478 17.3106 19.4594 18.7058 17.5913C20.0239 15.8266 20.8335 13.6738 20.8335 10.4086C20.8335 7.99104 20.3871 5.68357 19.5745 3.56012C19.2444 2.69771 19.4166 1.72359 20.0224 1.02662ZM25.5709 6.83587C25.7439 8.00247 25.8335 9.19556 25.8335 10.4086C25.8335 14.6367 24.7411 17.8663 22.7118 20.5833C20.8059 23.135 18.1677 25.0947 15.349 26.9995L15.108 27.2423C14.9789 27.3725 14.8359 27.488 14.6815 27.5868C9.35508 30.9969 5.8335 36.959 5.8335 43.742C5.8335 54.3274 14.4147 62.9086 25.0002 62.9086C35.5856 62.9086 44.1668 54.3274 44.1668 43.742C44.1668 42.0092 43.9375 40.3323 43.5079 38.7387C42.2405 40.111 40.8416 41.4354 39.1619 42.5256C36.0844 44.5229 32.2333 45.6632 26.6668 45.6632C25.526 45.6632 24.5298 44.8909 24.2456 43.786C23.9614 42.6811 24.4613 41.524 25.4605 40.9735C27.7232 39.727 29.6412 38.203 31.115 36.119C32.5857 34.0392 33.7125 31.2623 34.185 27.3657C35.2078 18.9299 31.7166 10.6034 25.5709 6.83587Z",
                                      fill: this.color,
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c("p", {
                        staticClass: "description",
                        domProps: {
                          innerHTML: _vm._s(_vm.description[_vm.locale]),
                        },
                      }),
                      _c("p", {
                        staticClass: "second_description",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.secondDesc[_vm.locale]
                              ? _vm.secondDesc[_vm.locale]
                              : ""
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "functions-info" }, [
                    _vm.functionList.length
                      ? _c("p", { staticClass: "font-weight-bold mt-3 mb-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                !_vm.isTraining
                                  ? _vm.$t("lk.subscribe.features")
                                  : _vm.$t("lk.subscribe.benefits")
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "functions-info__list" },
                      _vm._l(_vm.grouppedFunctionsList, function (group, i) {
                        return _c(
                          "ul",
                          { key: i, staticClass: "list text-muted" },
                          _vm._l(group, function (item, j) {
                            return _c("li", {
                              key: j,
                              domProps: { innerHTML: _vm._s(item) },
                            })
                          }),
                          0
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "price-info" },
                [
                  _vm.currentSavePct
                    ? _c("p", { staticClass: "text-subscribe-save-pct mb-0" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lk.subscribe.save")) +
                            " " +
                            _vm._s(_vm.currentSavePct) +
                            "% "
                        ),
                      ])
                    : _vm._e(),
                  _vm.currentOldPrice
                    ? _c(
                        "h2",
                        {
                          key: "price" + _vm.activeMonth,
                          staticClass: "price font-weight-bold mb-3",
                        },
                        [
                          _vm._v(
                            " $" +
                              _vm._s(
                                _vm.currentPrice ? _vm.currentPrice : _vm.price
                              ) +
                              " "
                          ),
                          _c("span", { staticClass: "price-wrap" }, [
                            _c("span", { staticClass: "price-strike" }, [
                              _vm._v(" $" + _vm._s(_vm.currentOldPrice) + " "),
                            ]),
                            _vm.blackFriday &&
                            (_vm.tariffId === "standard_edu" ||
                              _vm.tariffId === "web3_edu")
                              ? _c("span", { staticClass: "black-friday" }, [
                                  _vm._v(" Black Friday "),
                                ])
                              : _vm._e(),
                          ]),
                          _vm.tariffId !== "mentorship_edu"
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "month ml-2 font-weight-normal text-muted price-month",
                                },
                                [_vm._v(" " + _vm._s(_vm.currentLabel) + " ")]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _c("h2", { staticClass: "price font-weight-bold mb-2" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.isFree
                                ? this.$t("general.free")
                                : _vm.currentPrice
                                ? `$ ${_vm.currentPrice}`
                                : `$ ${_vm.price}`
                            ) +
                            " "
                        ),
                        _vm.tariffId !== "mentorship_edu"
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "month ml-2 font-weight-normal text-muted",
                              },
                              [_vm._v(" " + _vm._s(_vm.currentLabel) + " ")]
                            )
                          : _vm._e(),
                      ]),
                  !_vm.hideSelect && !_vm.showCuratorSelect
                    ? _c("CSelect", {
                        attrs: {
                          label:
                            _vm.tariffId === "mentorship_edu"
                              ? _vm.$t(`lk.subscribe.selectCurator`)
                              : _vm.$t(`lk.subscribe.selectMounth`),
                          value: _vm.activeMonth,
                          options: _vm.getListPrice(),
                        },
                        on: {
                          input: function ($event) {
                            _vm.activeMonth = $event.target.value
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.showCuratorSelect
                    ? [
                        _c("div", { staticClass: "mb-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("lk.training.trainingView.courseCurator")
                              ) +
                              ": "
                          ),
                        ]),
                        _c("div", { staticClass: "mb-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                `${this.currentCuratorName}${
                                  this.currentCurator?.tg_nick
                                    ? ` (${this.currentCurator?.tg_nick})`
                                    : ""
                                }`
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    : _vm._e(),
                  _c(
                    "CLoadingButtonCustom",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.tariffId !== "mentorship_edu" ||
                            !_vm.isTariffActive,
                          expression:
                            "tariffId !== 'mentorship_edu' || !isTariffActive",
                        },
                      ],
                      staticClass: "shadow-none button-custom",
                      attrs: {
                        block: "",
                        color: "primary",
                        loading: _vm.loadingBuyOperation,
                        timeout: 10000,
                        disabled:
                          _vm.loadingBuyOperation ||
                          (_vm.disabledPay && !_vm.isTariffActive),
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.clickEvent.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isTariffActive &&
                              _vm.tariffId !== "mentorship_edu"
                              ? _vm.$t("general.open")
                              : _vm.$t("lk.subscribe.pay")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                2
              ),
              _vm.showMore
                ? _c("div", { staticClass: "card-block" }, [
                    _c("h6", { staticClass: "card-block__h" }, [
                      _vm._v(_vm._s(_vm.$t("lk.subscribe.cousreProgrmmLabel"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "card-block__items" },
                      _vm._l(_vm.cardList, function (item, idx) {
                        return _c(
                          "div",
                          {
                            key: "card" + idx,
                            staticClass: "card-block__item",
                          },
                          [
                            _c("h6", { staticClass: "card-block__item__h" }, [
                              _vm._v(_vm._s(item.title)),
                            ]),
                            _c("p", [_vm._v(_vm._s(item.description))]),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm.showMore
                      ? _c("div", { staticClass: "card-block__bottom" }, [
                          _vm.learnList.length
                            ? _c(
                                "div",
                                { staticClass: "card-block__bottom__learn" },
                                [
                                  _c("h6", { staticClass: "card-block__h" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("lk.subscribe.learnListLabel")
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "list card-block__bottom__learn",
                                    },
                                    _vm._l(_vm.learnList, function (item, j) {
                                      return _c("li", {
                                        key: j,
                                        domProps: { innerHTML: _vm._s(item) },
                                      })
                                    }),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "card-block__bottom__info" },
                            [
                              _vm.teacherName ||
                              _vm.dailyProfit ||
                              _vm.teacherInfo
                                ? _c("h6", { staticClass: "card-block__h" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "lk.subscribe.infoAboutTeacherLabel"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "d-flex w-100 flex-wrap" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-column w-50" },
                                    [
                                      _vm.teacherName
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "card-block__bottom__info__h",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "lk.subscribe.teacherLabel"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.teacherName) + " "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "card-block__bottom__info__h",
                                          },
                                          [_vm._v(_vm._s(_vm.teacherPosition))]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("div", { staticClass: "w-50" }, [
                                    _vm.dailyProfit
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "card-block__bottom__info__h",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "lk.subscribe.maximalDialyProfitLabel"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.dailyProfit)),
                                    ]),
                                  ]),
                                  _vm.teacherInfo
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "card-block__bottom__info__desc",
                                        },
                                        [_vm._v(_vm._s(_vm.teacherInfo))]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.showMore
                      ? _c(
                          "div",
                          { staticClass: "card-block__full-info" },
                          [
                            _c(
                              "span",
                              { staticClass: "card-block__full-info__h" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("lk.subscribe.fullCourseInfoLabel")
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "CButton",
                              {
                                staticClass: "shadow-none button-custom-info",
                                attrs: { block: "", color: "primary" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("lk.subscribe.viewLabelBTN")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.cardList.length
                ? _c(
                    "div",
                    {
                      staticClass: "show-more",
                      staticStyle: { background: "transparent" },
                    },
                    [
                      _c(
                        "span",
                        {
                          class: _vm.showMore
                            ? "show-more__text card-body-custom-show-more"
                            : "show-more__text card-body-custom-show-more _active",
                          on: {
                            click: function ($event) {
                              _vm.showMore = !_vm.showMore
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("lk.subscribe.showMoreBTN")))]
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
      _vm.showTryBtn ? _c("trial-group") : _vm._e(),
      _vm.showAboutBtn
        ? _c(
            "CButton",
            {
              staticClass: "shadow-none tryBtn",
              attrs: { color: "primary" },
              on: { click: _vm.aboutBtnHandler },
            },
            [_vm._v(" " + _vm._s(_vm.$t("lk.training.aboutBtn")) + " ")]
          )
        : _vm._e(),
      _c(
        "CModal",
        {
          staticClass: "subscribeTgModal",
          attrs: {
            centered: "",
            show: _vm.showSubscribeModal,
            title: `${_vm.$t("lk.permission.table.tariff")} ${_vm.title[
              _vm.locale
            ].toUpperCase()}`,
          },
          on: {
            "update:show": function ($event) {
              _vm.showSubscribeModal = $event
            },
          },
        },
        [
          _c("div", { staticClass: "subscribeTgModal__content" }, [
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("lk.subscribe.tgModalTitle", {
                      tariff: _vm.title[_vm.locale].toUpperCase(),
                    })
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "subscribeTgModal__list" }, [
              _c(
                "div",
                { staticClass: "subscribeTgModal__list-item" },
                [
                  _c("CIcon", {
                    class: [
                      _vm.$store.getters["user/telegramVerify"] &&
                      _vm.access_conditions.is_bot_subscribe
                        ? "text-success"
                        : "",
                    ],
                    attrs: { name: "cil-check" },
                  }),
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("lk.subscribe.tgModalPoint1")) + " "
                    ),
                    !_vm.$store.getters["user/telegramVerify"]
                      ? _c(
                          "CButton",
                          {
                            staticClass: "subscribeTgModal__list-item-button",
                            attrs: { color: "primary" },
                            on: { click: _vm.verifyTg },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("general.verify")) + " ")]
                        )
                      : _vm._e(),
                  ],
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "subscribeTgModal__list-item" },
                [
                  _c("CIcon", {
                    class: [
                      _vm.access_conditions.is_cvizor_subscribe
                        ? "text-success"
                        : "",
                    ],
                    attrs: { name: "cil-check" },
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.$t("lk.subscribe.tgModalPoint2")) + " "
                  ),
                  !_vm.access_conditions.is_cvizor_subscribe
                    ? _c(
                        "CButton",
                        {
                          staticClass: "subscribeTgModal__list-item-button",
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.subscribeTg(
                                "https://t.me/+oITBBhfgz4M3ZWJi"
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("marketplace.subscribe")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "subscribeTgModal__list-item" },
                [
                  _c("CIcon", {
                    class: [
                      _vm.access_conditions.is_tradium_subscribe
                        ? "text-success"
                        : "",
                    ],
                    attrs: { name: "cil-check" },
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.$t("lk.subscribe.tgModalPoint3")) + " "
                  ),
                  !_vm.access_conditions.is_tradium_subscribe
                    ? _c(
                        "CButton",
                        {
                          staticClass: "subscribeTgModal__list-item-button",
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.subscribeTg(
                                "https://t.me/+IsYupugy4so4YzUy"
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("marketplace.subscribe")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "subscribeTgModal__list-item" },
                [
                  _c("CIcon", {
                    class: [
                      _vm.access_conditions.is_tradium_community_subscribe
                        ? "text-success"
                        : "",
                    ],
                    attrs: { name: "cil-check" },
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.$t("lk.subscribe.tgModalPoint4")) + " "
                  ),
                  !_vm.access_conditions.is_tradium_community_subscribe
                    ? _c(
                        "CButton",
                        {
                          staticClass: "subscribeTgModal__list-item-button",
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.subscribeTg(
                                "https://t.me/+KRGeckZzbh42ZDEy"
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("marketplace.subscribe")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }