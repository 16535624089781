<template>
<div class="w-100">
  <CCard class="shadow-none w-100 mb-0" :style="borderStyle">

        <div class="card-body-custom flex-wrap d-flex justify-content-between">
          <div class="card-body-custom__inner flex-wrap d-flex flex-grow-1">
            <div class="base-info">
              <CBadge class="text-uppercase" :style="badgeStyle">
                {{
                  isTariffActive
                    ? $t("lk.subscribe.active")
                    : $t("lk.subscribe.inactive")
                }}
              </CBadge>
              <span v-if="active && toDate" class="ml-2" v-html="toDate" />

              <h2
                class="title mt-2 mb-3 text-uppercase font-weight-bold"
                :style="titleStyle"
              >
                {{ title[locale].toUpperCase() }}
                <svg class="specical__icon" v-if="isSpecical" viewBox="0 0 50 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0224 1.02662C20.6281 0.329655 21.5687 0.0233438 22.4687 0.229964C34.4982 2.99168 40.5786 16.1737 39.1487 27.9675C38.5928 32.5521 37.2196 36.1463 35.1974 39.0059C35.185 39.0234 35.1726 39.0408 35.1602 39.0582C35.6196 38.8324 36.0433 38.5888 36.4398 38.3315C38.4129 37.0509 39.8611 35.3608 41.7466 33.1602C41.9745 32.8942 42.2088 32.6208 42.4512 32.3397C42.9959 31.7081 43.8197 31.3897 44.6476 31.4908C45.4756 31.592 46.1985 32.0993 46.5751 32.8436C48.2336 36.1209 49.1668 39.8261 49.1668 43.742C49.1668 57.0889 38.347 67.9086 25.0002 67.9086C11.6533 67.9086 0.833496 57.0889 0.833496 43.742C0.833496 35.2851 5.17918 27.8449 11.7479 23.53L11.9731 23.3031C12.0887 23.1865 12.2156 23.0816 12.3519 22.9899C15.2381 21.0478 17.3106 19.4594 18.7058 17.5913C20.0239 15.8266 20.8335 13.6738 20.8335 10.4086C20.8335 7.99104 20.3871 5.68357 19.5745 3.56012C19.2444 2.69771 19.4166 1.72359 20.0224 1.02662ZM25.5709 6.83587C25.7439 8.00247 25.8335 9.19556 25.8335 10.4086C25.8335 14.6367 24.7411 17.8663 22.7118 20.5833C20.8059 23.135 18.1677 25.0947 15.349 26.9995L15.108 27.2423C14.9789 27.3725 14.8359 27.488 14.6815 27.5868C9.35508 30.9969 5.8335 36.959 5.8335 43.742C5.8335 54.3274 14.4147 62.9086 25.0002 62.9086C35.5856 62.9086 44.1668 54.3274 44.1668 43.742C44.1668 42.0092 43.9375 40.3323 43.5079 38.7387C42.2405 40.111 40.8416 41.4354 39.1619 42.5256C36.0844 44.5229 32.2333 45.6632 26.6668 45.6632C25.526 45.6632 24.5298 44.8909 24.2456 43.786C23.9614 42.6811 24.4613 41.524 25.4605 40.9735C27.7232 39.727 29.6412 38.203 31.115 36.119C32.5857 34.0392 33.7125 31.2623 34.185 27.3657C35.2078 18.9299 31.7166 10.6034 25.5709 6.83587Z" :fill="this.color"/>
                </svg>
              </h2>

              <p class="description" v-html="description[locale]" />
              <p class="second_description" v-html="secondDesc[locale] ? secondDesc[locale] : ''"></p>
            </div>

            <div class="functions-info">
              <p v-if="functionList.length" class="font-weight-bold mt-3 mb-3">
                {{ !isTraining ? $t("lk.subscribe.features") : $t("lk.subscribe.benefits") }}
              </p>

              <div class="functions-info__list">
                <ul
                  class="list text-muted"
                  v-for="(group, i) in grouppedFunctionsList"
                  :key="i"
                >
                  <li v-for="(item, j) in group" :key="j" v-html="item" />
                </ul>
              </div>
            </div>
          </div>

          <div class="price-info">
            <p v-if="currentSavePct" class="text-subscribe-save-pct mb-0">
              {{ $t("lk.subscribe.save") }} {{ currentSavePct }}%
            </p>

            <h2 v-if="currentOldPrice" :key="'price' + activeMonth" class="price font-weight-bold mb-3">
              ${{ currentPrice ? currentPrice : price }}
              <span class="price-wrap">
                <span class="price-strike"> ${{ currentOldPrice }} </span>

                <span
                  v-if="blackFriday && (tariffId === 'standard_edu' || tariffId === 'web3_edu')"
                  class="black-friday"
                >
                  Black Friday
                </span>
              </span>
              <span v-if="tariffId !== 'mentorship_edu'" class="month ml-2 font-weight-normal text-muted price-month">
                {{ currentLabel }}
              </span>
            </h2>

            <h2 v-else class="price font-weight-bold mb-2">
              {{ isFree ? this.$t('general.free') : currentPrice ? `$ ${currentPrice}` : `$ ${price}` }}
              <span v-if="tariffId !== 'mentorship_edu'" class="month ml-2 font-weight-normal text-muted">
                {{ currentLabel }}
              </span>
            </h2>
            <CSelect
              :label="tariffId === 'mentorship_edu' ? $t(`lk.subscribe.selectCurator`) : $t(`lk.subscribe.selectMounth`)"
              v-if="!hideSelect && !showCuratorSelect"
              :value="activeMonth"
              @input="activeMonth = $event.target.value"
              :options="getListPrice()"
            ></CSelect>
            <template v-if="showCuratorSelect">
              <div class="mb-2">
                {{ $t('lk.training.trainingView.courseCurator') }}:
              </div>
              <div class="mb-3">
                {{ `${this.currentCuratorName}${this.currentCurator?.tg_nick ? ` (${this.currentCurator?.tg_nick})`: ''}` }}
              </div>
            </template>
            <CLoadingButtonCustom
              v-show="tariffId !== 'mentorship_edu' || !isTariffActive"
              block
              color="primary"
              class="shadow-none button-custom"
              :loading="loadingBuyOperation"
              :timeout="10000"
              :disabled="loadingBuyOperation || (disabledPay && !isTariffActive)"
              @click.native="clickEvent"
            >
              {{ isTariffActive && tariffId !== 'mentorship_edu' ? $t("general.open") : $t("lk.subscribe.pay") }}
            </CLoadingButtonCustom>
            <!-- <CButton
              block
              color="primary"
              class="shadow-none button-custom button-custom-more"
              v-if="tariffId === 'web3_edu'"
              target="_blank"
              href="https://web3.cvizor.com/"
            >
              {{ $t("lk.training.readMore") }}
            </CButton> -->
          </div>

          <div v-if="showMore" class="card-block">
            <h6 class="card-block__h">{{$t("lk.subscribe.cousreProgrmmLabel")}}</h6>
            <div class="card-block__items">
              <div
                v-for="(item, idx) in cardList"
                :key="'card' + idx"
                class="card-block__item"
              >
                <h6 class="card-block__item__h">{{ item.title }}</h6>
                <p>{{ item.description }}</p>
              </div>
            </div>
            <div v-if="showMore" class="card-block__bottom">
              <div v-if="learnList.length" class="card-block__bottom__learn">
                <h6 class="card-block__h">{{$t("lk.subscribe.learnListLabel")}}</h6>
                <ul class="list card-block__bottom__learn">
                  <li v-for="(item, j) in learnList" :key="j" v-html="item" />
                </ul>
              </div>
              <div class="card-block__bottom__info">
                <h6
                  v-if="teacherName || dailyProfit || teacherInfo"
                  class="card-block__h"
                >
                  {{$t("lk.subscribe.infoAboutTeacherLabel")}}
                </h6>
                <div class="d-flex w-100 flex-wrap">
                  <div class="d-flex flex-column w-50">
                    <p v-if="teacherName" class="card-block__bottom__info__h">
                      {{$t("lk.subscribe.teacherLabel")}}
                    </p>
                    <span
                      >{{ teacherName }}
                      <span class="card-block__bottom__info__h">{{
                        teacherPosition
                      }}</span></span
                    >
                  </div>
                  <div class="w-50">
                    <p v-if="dailyProfit" class="card-block__bottom__info__h">
                      {{$t("lk.subscribe.maximalDialyProfitLabel")}}
                    </p>
                    <span>{{ dailyProfit }}</span>
                  </div>
                  <span
                    v-if="teacherInfo"
                    class="card-block__bottom__info__desc"
                    >{{ teacherInfo }}</span
                  >
                </div>
              </div>
            </div>
            <div v-if="showMore" class="card-block__full-info">
              <span class="card-block__full-info__h"
                >{{$t("lk.subscribe.fullCourseInfoLabel")}}</span
              >
              <CButton
                block
                color="primary"
                class="shadow-none button-custom-info"
              >
                {{$t("lk.subscribe.viewLabelBTN")}}
              </CButton>
            </div>
          </div>
          <div
            v-if="cardList.length"
            style="background: transparent"
            class="show-more"
          >
            <span
              @click="showMore = !showMore"
              :class="
                showMore
                  ? 'show-more__text card-body-custom-show-more'
                  : 'show-more__text card-body-custom-show-more _active'
              "
              >{{$t("lk.subscribe.showMoreBTN")}}</span
            >
          </div>
        </div>
  </CCard>
  <trial-group v-if="showTryBtn" />
  <CButton
      v-if="showAboutBtn"
      color="primary"
      class="shadow-none tryBtn"
      @click="aboutBtnHandler"
    >
      {{$t('lk.training.aboutBtn')}}
    </CButton>
    <CModal
      centered
      :show="showSubscribeModal"
      @update:show="showSubscribeModal = $event"
      :title="`${$t('lk.permission.table.tariff')} ${title[locale].toUpperCase()}`"
      class="subscribeTgModal"
      >
      <div class="subscribeTgModal__content">
        <p>
          {{ $t("lk.subscribe.tgModalTitle", {tariff: title[locale].toUpperCase()}) }}
        </p>
        <div class="subscribeTgModal__list">
          <div class="subscribeTgModal__list-item">
            <!-- <span
            class="subscribeTgModal__list-item-dot"/> -->
            <CIcon
            name="cil-check"
            :class="[$store.getters['user/telegramVerify'] && access_conditions.is_bot_subscribe ? 'text-success' : '']" />
            <!-- <template v-if="$store.getters['user/telegramVerify']">
              <span>
                {{ $t("lk.account.verifiedTelegram") }}
              </span>
              <CIcon name="cil-check" class="text-success" />
            </template> -->
            <template>
              {{ $t("lk.subscribe.tgModalPoint1") }}
              <!-- <a
              :href="$store.state.user.userData.tgLink"
              target="_blank">
              <img
                src="/img/svg/media-links/telegram-cryptovizor.svg"
                :alt="$store.state.user.userData.tgLink"
                class="icon --link"
              >
              </a> -->
              <CButton
              v-if="!$store.getters['user/telegramVerify']"
              class="subscribeTgModal__list-item-button"
              color="primary"
              @click="verifyTg">
                {{ $t('general.verify') }}
              </CButton>
            </template>
          </div>
          <div class="subscribeTgModal__list-item">
            <CIcon
            name="cil-check"
            :class="[access_conditions.is_cvizor_subscribe ? 'text-success' : '']"
            />
            <!-- <span class="subscribeTgModal__list-item-dot"/> -->
            {{ $t("lk.subscribe.tgModalPoint2") }}
            <!-- <a
            @click="close"
            href="https://t.me/cvizor"
            target="_blank"> -->
              <!-- <img
                src="/img/svg/media-links/telegram-cryptovizor.svg"
                alt="https://t.me/cvizor"
                class="icon"
              > -->
            <!-- </a> -->
            <CButton
            v-if="!access_conditions.is_cvizor_subscribe"
            class="subscribeTgModal__list-item-button"
            color="primary"
            @click="subscribeTg('https://t.me/+oITBBhfgz4M3ZWJi')">
              {{ $t('marketplace.subscribe') }}
            </CButton>
          </div>
          <div class="subscribeTgModal__list-item">
            <CIcon
            name="cil-check"
            :class="[access_conditions.is_tradium_subscribe ? 'text-success' : '']"
            />
            <!-- <span class="subscribeTgModal__list-item-dot"/> -->
            {{ $t("lk.subscribe.tgModalPoint3") }}
            <!-- <a
            @click="close"
            href="https://t.me/cvizor"
            target="_blank"> -->
              <!-- <img
                src="/img/svg/media-links/telegram-cryptovizor.svg"
                alt="https://t.me/cvizor"
                class="icon"
              > -->
            <!-- </a> -->
            <CButton
            v-if="!access_conditions.is_tradium_subscribe"
            class="subscribeTgModal__list-item-button"
            color="primary"
            @click="subscribeTg('https://t.me/+IsYupugy4so4YzUy')">
              {{ $t('marketplace.subscribe') }}
            </CButton>
          </div>
          <div class="subscribeTgModal__list-item">
            <CIcon
            name="cil-check"
            :class="[access_conditions.is_tradium_community_subscribe ? 'text-success' : '']"
            />
            <!-- <span class="subscribeTgModal__list-item-dot"/> -->
            {{ $t("lk.subscribe.tgModalPoint4") }}
            <!-- <a
            @click="close"
            href="https://t.me/cvizor"
            target="_blank"> -->
              <!-- <img
                src="/img/svg/media-links/telegram-cryptovizor.svg"
                alt="https://t.me/cvizor"
                class="icon"
              > -->
            <!-- </a> -->
            <CButton
            v-if="!access_conditions.is_tradium_community_subscribe"
            class="subscribeTgModal__list-item-button"
            color="primary"
            @click="subscribeTg('https://t.me/+KRGeckZzbh42ZDEy')">
              {{ $t('marketplace.subscribe') }}
            </CButton>
          </div>
        </div>
        <!-- <div class="subscribeTgModal__qr-wrapper">
          <img
            src="@/assets/img/cvizorqr.jpg"
            alt="https://t.me/cvizor"
          >
        </div> -->
      </div>
  </CModal>
</div>
</template>

<script>
import TrialGroup from "@/components/subscribe/TrialGroup";
import {mapState, mapGetters, mapActions} from "vuex";
import axios from 'axios'

export default {
  name: "SubscribeCard",
  components: {TrialGroup},
  props: {
    color: {
      type: String,
      default: "#ffffff",
    },
    toDate: {
      type: String,
    },
    title: {
      type: Object,
      required: true,
    },
    tariffId: {
      type: String,
      required: true,
    },
    activeTariffId: {
      type: String,
      required: true,
    },
    activeTariff: {
      type: Boolean,
      required: true,
    },
    disabledPay: {
      type: Boolean,
      default: false,
    },
    description: {
      type: Object,
      required: true,
    },
    secondDesc: {
      type: Object,
      default: () => ({}),
    },
    monthList: {
      type: Array,
      default: () => [],
    },
    functionList: {
      type: Array,
      default: () => [],
    },
    cardList: {
      type: Array,
      default: () => [],
    },
    learnList: {
      type: Array,
      default: () => [],
    },
    teacherName: {
      type: String,
      default: "",
    },
    teacherPosition: {
      type: String,
      default: "",
    },
    dailyProfit: {
      type: String,
      default: "",
    },
    teacherInfo: {
      type: String,
      default: "",
    },
    price: {
      type: [String,Number],
      default: ''
    },
    isTraining: {
      type: Boolean,
      default: false
    },
    isIndependent: {
      type: Boolean,
      default: false
    },
    showTryBtn: {
      type: Boolean,
      default: false
    },
    showAboutBtn: {
      type: Boolean,
      default: false
    },
    hideSelect: {
      type: Boolean,
      default: false
    },
    isSpecical: {
      type: Boolean,
      default: false
    },
    hasMentor: {
      type: Boolean,
      default: true
    },
    currentCurator: {
      type: [Object, undefined],
      default: undefined
    },
    isFree: {
      type: Boolean,
      default: false
    },
    course: {
      type: [Object, undefined],
      default: undefined
    },
    isAccessByConditions: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      access_conditions:{
        is_cvizor_subscribe: false,
        is_tradium_subscribe: false,
        is_tradium_community_subscribe: false
      },
      showSubscribeModal: false,
      showMore: false,
      activeMonth: this.monthList[0].monthsCount,
      loadingBuyOperation: false,
    };
  },
  // mounted(){
  //   this.activeMonth = this.tariffId === 'mentorship_edu' && this.currentCurator?.id ?
  //     this.currentCurator?.id : this.monthList[0].monthsCount
  // },
  computed: {
    isTariffActive(){
      return this.active || this.$store.state.user.userData?.eduTariffs.includes(this.tariffId)
    },
    ...mapGetters({
      isGuest: 'user/isGuest'
    }),
    ...mapState({
      userData: state => state.user.userData
    }),
    showCuratorSelect(){
      return this.tariffId === 'mentorship_edu' && this.currentCuratorName
    },
    currentCuratorName(){
      return this.currentCurator?.fullName || this.currentCurator?.id || ''
    },
    borderStyle() {
      if (this.isTariffActive) {
        return {
          border: `1px solid ${this.color}`,
        };
      } else {
        return {
          border: `1px solid rgba(0, 0, 0, 0.08)`,
        };
      }
    },
    titleStyle() {
      return {
        color: this.color,
      };
    },
    badgeStyle() {
      const isBgLight = this.defineBgLight();
      const textColor = isBgLight ? "var(--second-card-bg)" : "var(--white)";
      return {
        opacity: this.isTariffActive ? "1" : "0.4",
        backgroundColor: this.isTariffActive ? this.color : "var(--white)",
        color: textColor,
      };
    },
    currentMonth() {
      const item = this.monthList.find(
        (i) => i.monthsCount == this.activeMonth
      );
      return item || this.monthList[0];
    },
    currentPrice() {
      return this.currentMonth?.price;
    },
    currentOldPrice() {
      return this.currentMonth?.oldPrice;
    },
    currentLabel() {
      return this.currentMonth?.name[this.locale];
    },
    currentSavePct() {
      if (!this.currentOldPrice || !this.currentPrice) return null;
      return Math.trunc(
        ((this.currentOldPrice - this.currentPrice) / this.currentOldPrice) *
          100
      );
    },
    active() {
      if(this.tariffId === 'mentorship_edu') return this.hasMentor
      if (this.activeTariff) {
        return this.activeTariffId === this.tariffId;
      }
      return false;
    },
    locale() {
      return this.$root.$i18n.locale;
    },
    /**
     * свойство разбивает перечень преимуществ тарифа на столбцы в зависимости от количества элементов списка
     * @returns возвращает объект с массивами преимуществ
     */
    grouppedFunctionsList() {
      let divider;
      // определяем количество элементов в столбце в зависимости от длины списка
      switch (this.functionList.length) {
        case 2: {
          divider = 1;
          break;
        }
        case 3:
        case 4: {
          divider = 2;
          break;
        }
        case 5:
        case 6: {
          divider = 3;
          break;
        }
        default: {
          divider = 4;
        }
      }

      return this.functionList.reduce((acc, item, index) => {
        const i = Math.floor(index / divider); // высчитываем ключ, куда отправляется элемент списка в результирующем массиве
        if (!acc[i]) acc[i] = [];
        acc[i].push(item[this.locale]);
        return acc;
      }, {});
    },
    blackFriday() {
      return this.userData.promotions.find(el => el.name === 'blackfriday2023')
    },
  },
  watch: {
    activeMonth() {
      this.currentPrice
    }
  },
  methods: {
    ...mapActions({
      toggleShowLoginModal: 'user/toggleShowLoginModal',
    }),
    verifyTg(){
      window.open(this.$store.state.user.userData.tgLink, '_blank');

      this.checkTelegramVerify()
    },
    checkTelegramVerify() {
      const oldTimer = this.$store.state.user.telegramVerifyTimer
      if (oldTimer) {
        clearTimeout(oldTimer)
        this.$store.commit('user/SET_TELEGRAM_VERIFY_TIMER', null)
      }

      const timer = setTimeout(async () => {
        await this.$store.dispatch('user/getCurrent')
        if (this.$store.getters['user/telegramVerify']) {
          this.$toastr.success(this.$t('lk.account.confirmAccountSuccessfully'))
        } else {
          this.checkTelegramVerify()
        }
      }, 7000)
      this.$store.commit('user/SET_TELEGRAM_VERIFY_TIMER', timer)
    },
    async subscribeTg(link){
      window.open(link, '_blank');
      this.showSubscribeModal = false
    },
    aboutBtnHandler(){
      window.open('https://edu.cvizor.com/', '_blank');
    },
    getListPrice() {
      let result = []
      this.monthList.forEach(el => {
        result.push({
          label: `${el.name[this.$store.state.settings.global.locale]}${el.tg_nick ? `(${el.tg_nick})` : ''}`,
          value: el.monthsCount,
        })
      });
      return result
    },
    hexToRgb(color) {
      /* Check for # infront of the value, if it's there, strip it */
      if (color.substring(0, 1) === "#") {
        color = color.substring(1);
      }

      let rgbColor = {};

      /* Grab each pair (channel) of hex values and parse them to ints using hexadecimal decoding */
      rgbColor.r = parseInt(color.substring(0, 2), 16);
      rgbColor.g = parseInt(color.substring(2, 4), 16);
      rgbColor.b = parseInt(color.substring(4), 16);

      return rgbColor;
    },
    defineBgLight() {
      const { r, g, b } = this.hexToRgb(this.color);
      const lighten = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      return lighten > 0.5;
    },
    clickEvent() {
      if(this.isGuest){
        this.toggleShowLoginModal({show: true})
        return
      }
      if(this.isTariffActive && this.tariffId !== 'mentorship_edu'){
        this.$router.push({
          name: 'PageViewTraining',
          params: {
            id: this.course.id,
            slug: this.course.slug || this.course.id
          }
        })

        return
      }
      if (this.isAccessByConditions) {
        this.loadingBuyOperation = true
        axios.post(`api/v2/events/${this.course.id}/buy`, {
              tariff: this.tariffId,
              balance: 0,
            })
          .then(({data}) => {
            const {status, errors} = data
            if (status) {
              this.$toastr.success(this.$t('lk.subscribe.changeTariffSuccess'))
              this.$store.dispatch('user/getCurrent')
                .then(({status}) => {
                  if (status < 300) {
                    this.$toastr.success(this.$t('lk.subscribe.reloadedStatus'))
                    this.$root.$emit('dropdownMenuUpdate')
                    this.$emit('tariffChanged')
                  }
                })
            }else{
              if(errors){
                const errorsList = Array.isArray(errors) ? errors : [errors]
                errorsList.forEach(item=>{
                  this.$toastr.error(item)
                })
                return
              }
              this.$toastr.error(this.$t('errors.buyError'))
            }
          })
          .catch((e) => {
            const conditions = e.response?.data?.access_conditions
            if(conditions && Object.values(conditions).some(item=>!item)){
              this.access_conditions = conditions
              this.showSubscribeModal = true
            }
          })
          .finally(() => {
            this.loadingBuyOperation = false
            this.$emit('updatePreview')
          })
        return
      }
      this.$emit("pay", {
        tariff_id: this.tariffId,
        title: this.title,
        month_count: this.activeMonth,
        color: this.color,
        isTraining: this.isTraining,
        isIndependent: this.isIndependent,
        curator: this.tariffId === 'mentorship_edu' && this.currentCurator?.id ? this.currentCurator?.id : this.activeMonth,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.base-info,
.functions-info,
.price-info {
  flex: 1;
}

.base-info {
  max-width: 17.6rem;
}

.price-info {
  max-width: 16rem;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  @include media-breakpoint-up(sm) {
    width: 100%;
    max-width: 100%;
  }
  ._inner & {
    max-width: none;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 18rem;
      flex: none;
    }
    @include media-breakpoint-up(xl) {
      width: 20rem;
    }
  }
}

.functions-info__list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.8rem;
  @include media-breakpoint-down(xl) {
    flex-direction: column;
  }
}

.card-block {
  margin-top: -2.2rem;
  background: var(--second-subscribe-card-bg);
  width: 100%;

  &__h {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 12px;
    padding-top: 24px;

    @include media-breakpoint-up(md) {
      font-size: 21px;
      line-height: 26px;
      margin-bottom: 16px;
    }

    @include media-breakpoint-up(lg) {
      padding-top: 32px;
    }

  }

  &__items {
    display: grid;
    grid-template-columns: 100%;
    gap: 12px;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 2fr);
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 3fr);
    }
  }

  &__item {
    background: var(--third-card-bg);
    border-radius: 4px;
    padding: 12px 16px;
    width: 100%;
  }

  &__bottom {
    display: flex;
    flex-direction: column;


    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    &__learn {
      flex: 10;

      ul {
        display: grid;
        grid-template-columns: repeat(1, 1fr);

        @include media-breakpoint-up(md) {
          grid-template-columns: repeat(2, 2fr);
        }
      }
    }

    &__info {
      flex: 8;
      max-width: 100%;

      @include media-breakpoint-up(lg) {
        max-width: 50%;
        margin-left: auto;
      }


      &__h {
        margin-bottom: auto;
        font-size: 12px;
        line-height: 140%;
        color: var(--input-placeholder);

        @include media-breakpoint-up(md) {
          margin-bottom: 6px;
        }
      }

      &__desc {
        width: 100%;
        margin-top: 16px;
      }
    }
  }

  &__full-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: var(--bg);
    padding: 12px 16px;
    margin-top: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    border-radius: 4px;
    flex-direction: column;
    gap: 12px;

    @include media-breakpoint-up(md) {
      padding: 11px 24px;
      font-size: 21px;
      line-height: 26px;
      margin-top: 24px;
      flex-direction: row;
    }

    @include media-breakpoint-up(lg) {
      padding: 11px 32px;
      margin-top: 32px;
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.card-body-custom {
  box-shadow: 0 9px 16px 10px rgba(61, 61, 61, 0.08);
  gap: 2.2rem;
  flex-direction: column;
  transition: .5s;

  @include media-breakpoint-down(xl) {
  }

  @include media-breakpoint-down(xs) {
    padding: 1rem 1.25rem;
  }
  @include media-breakpoint-up(sm) {
    padding: 1.5rem 2rem;
    flex-direction: column;
  }
  @include media-breakpoint-up(xl) {
    padding: 1.5rem;
  }
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  ._inner & {
    @include media-breakpoint-up(md) {
      flex-wrap: nowrap!important;
    }
  }

  &__inner {
    flex-direction: column;
    gap: 1rem;

    @include media-breakpoint-up(md) {
      max-width: 50%;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      max-width: 65%;
    }

    ._inner & {
      @include media-breakpoint-up(md) {
        max-width: none;
        flex: 1 1 auto;
      }
    }
  }
}

.c-dark-theme {
  .card-body-custom {
    background: var(--subscribe-card-bg);
    box-shadow: none;
  }
}

.show-more {
  @extend .card-body-custom;
  background: transparent;
  width: 108%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 -1.25rem;
  margin-top: -2.2rem;
  box-shadow: none;

  &__text {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
    padding-top: 16px;
    border-top: solid 1px var(--dark);
    text-align: center;
    cursor: pointer;
    transition: 0.4s;

    &::after {
      content: "❭";
      margin-left: 8px;
      transform: rotate(90deg);
      transition: 0.2s;
    }

    &:hover {
      color: var(--dark);
    }

    @include media-breakpoint-up(lg) {
      margin: 0 20px;
    }
  }

  @include media-breakpoint-up(md) {
    margin: -2rem;
  }

  @include media-breakpoint-up(lg) {
    margin: -2.2rem 0;
    width: 100%;
  }

  ._active {
    &::after {
      transform: rotate(-90deg);
    }
  }
  .card-body-custom {
    background: transparent;
  }
}

.c-dark-theme {
  .card-body-custom-show-more {
    background: transparent;
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 2rem;
  letter-spacing: -1.9px;
  overflow-wrap: anywhere;
}

.description {
  //min-height: calc(0.875rem * 1.5 * 3); // минимальная высота 2 строки (с учётом font-size и line-height)
  margin: 0;
}

.second_description {
  margin: .5rem 0;
}

.price {
  font-size: 2.25rem;
}

.month {
  font-size: 1rem;
}

.list {
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  flex: 1;

  li {
    margin-bottom: 0.8rem;

    &::marker {
      color: var(--primary);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.button-custom {
  max-width: 18rem;
  height: 45px;

  @include media-breakpoint-up(md) {
    max-width: 100%;
  }
}
a.button-custom{
  display: flex;
  align-items: center;
  justify-content: center;
}
a.button-custom-more{
  color: var(--color);
  transition: .5s;
  background-color: transparent;
  border: 1px solid var(--primary) !important;
}
a.button-custom-more:hover{
  background-color: var(--primary);
}

.button-custom-info {
  max-width: 18rem;
  height: 45px;
}

.price-strike {
  vertical-align: top;
  font-size: 1.15rem;
  position: relative;
  opacity: 0.3;
  height: fit-content;
  display: block;
  width: fit-content;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    transform: rotate(330deg);
    background: var(--white);
  }
}

.mb-custom {
  margin-bottom: 0.2rem;
}

.tooltip-wrap {
  margin-left: 0.3rem;
  cursor: pointer;
  color: #fff;
  width: 1rem;
  height: 1rem;
}

.specical__icon {
  width: 26px;
  height: 30px;
}

/deep/ .tryBtn {
  height: 60px;
  width: 100%;
  margin-top: 20px;
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;

  @include media-breakpoint-up(md) {
    height: 90px;
    margin-top: 23px;
  }
}

.black-friday {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 6px 12px;
  border-radius: 8px;
  border: 1px solid;
  box-shadow: 0px 0px 3px 0px #3686F6, 0px 0px 6px 0px #3686F6, 0px 0px 3px 0px #80F0FF, 0px 0px 6px 0px #FFF;
  display: block;
  margin-top: 5px;
}

.price-wrap {
  height: 100%;
  display: inline-block;
  vertical-align: text-top;
  position: relative;
  top: -10px;
  padding-left: 5px;
}

.price-month {
  white-space: nowrap;
}

.subscribeTgModal{
  /deep/ .modal-footer{
    display: none;
  }
  &__list-item-button{
    margin-left: auto;
    width: 142px;
  }
  .icon {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 0.2rem;
    transition: 150ms ease-out;
    &.--link{
      @media (hover) {
        filter: grayscale(1);
      }

      &:hover {
        filter: none;
      }
    }
  }
  &__list{
    display: flex;
    flex-direction: column;
  }
  &__list-item{
    display: flex;
    align-items: center;
  }
  &__list-item-dot{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--primary);
    margin-right: 6px;
  }
  &__close{
    position: absolute;
    cursor: pointer;
    user-select: none;
    right: 0;
    top: 0;
    &:hover {
      color: $danger;
    }
  }
 &__btn {
   width: 25%;
   height: 44px;
   margin-left: auto;
 }

 &__btnBlock {
   display: flex;
   flex-direction: column;
   gap: 20px;

   @include media-breakpoint-up(md) {
     flex-direction: row;
   }
 }
 &__content{
  position: relative;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  & div{
    display: flex;
    gap: 5px;
    & svg{
      flex-shrink: 0;
    }
    & span{
      text-align: left;
    }
  }
 }
 &__qr-wrapper{
  justify-content: center;
  margin-top: 10px;
 }
}

</style>
