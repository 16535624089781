var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.author
    ? _c(
        "router-link",
        {
          staticClass: "curator-author",
          class: { "curator-author__disable": !_vm.author?.active },
          attrs: {
            to: {
              name: _vm.toAuthor,
              params: { id: _vm.author.id, back: _vm.toBack },
            },
          },
        },
        [
          _c("div", { staticClass: "curator-author__top" }, [
            _c("div", { staticClass: "curator-author__picture" }, [
              !_vm.author.avatarCurrent
                ? _c("div", { staticClass: "curator-author__plug" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          viewBox: "0 0 35 38",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M31.8423 28.2997L22.5668 23.4655L26.1349 18.8408C26.791 18.0024 27.1804 16.9783 27.1804 15.8795V8.90569C27.1804 4.50534 22.8559 0.938477 17.5208 0.938477C12.1857 0.938477 7.86115 4.50534 7.86115 8.90569V15.8795C7.86115 16.9783 8.2505 18.0024 8.92222 18.8623L8.90662 18.8402L12.4747 23.4649L3.19926 28.2991C1.52443 29.1822 0.430664 30.7077 0.430664 32.4439V37.7531L34.6109 37.7096V32.4439C34.6109 30.7077 33.5164 29.1822 31.8661 28.3108L31.8423 28.2991V28.2997Z",
                          },
                        }),
                      ]
                    ),
                  ])
                : _c("img", {
                    attrs: {
                      src: _vm.author.avatarCurrent,
                      alt: _vm.author.fullName,
                    },
                  }),
            ]),
            _c("div", { staticClass: "curator-author__content" }, [
              _vm.author.fullName
                ? _c("h6", { staticClass: "curator-author__name" }, [
                    _vm._v(_vm._s(_vm.author.fullName)),
                  ])
                : _vm._e(),
              _vm.author.description
                ? _c("div", {
                    staticClass: "curator-author__description",
                    domProps: { innerHTML: _vm._s(_vm.descriptionFormatted) },
                  })
                : _vm._e(),
            ]),
          ]),
          _vm.author.studentCount
            ? _c("div", { staticClass: "curator-author__bottom" }, [
                _c("div", { staticClass: "curator-author__stat" }, [
                  _c("div", { staticClass: "curator-author__stat-count" }, [
                    _vm._v(_vm._s(_vm.author.studentCount)),
                  ]),
                  _c("div", { staticClass: "curator-author__stat-text" }, [
                    _vm._v("Количество учеников"),
                  ]),
                ]),
                _vm.author.mentorshipCount
                  ? _c("div", { staticClass: "curator-author__stat" }, [
                      _c("div", { staticClass: "curator-author__stat-count" }, [
                        _vm._v(_vm._s(_vm.author.mentorshipCount)),
                      ]),
                      _c("div", { staticClass: "curator-author__stat-text" }, [
                        _vm._v("Количество менторшип"),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }