<template>
  <div class="training-detail">
    <CSpinner v-if="loading" color="primary" size="lg" />
    <div class="training-detail__content" v-if="course">
      <div class="training-detail__picture">
        <CButton
          class="cbtn"
          color="primary"
          to="/lk/subscribe?study=1"
        >
          <CIcon name="cil-arrow-left"/>
          {{ $t('lk.training.labelBackBtn') }}
        </CButton>
        <img
          :src="`${baseUrl}${course.course_wide_photo}`"
          :alt="course.course_title"
          v-if="course.course_wide_photo"
        >
        <img
          :src="`${BUILD_PUBLIC_PATH}/img/plugs/training.jpg`"
          :alt="course.course_title"
          v-else
        >
        <div class="training-detail__picture-content">
          <template v-if="course.course_title">
            <h1 class="training-detail__title" v-html="course.course_title"></h1>
          </template>
          <template v-if="course?.authors?.length">
            <small-author
              class="training-detail__author _big"
              :author="course.authors[0]"
            ></small-author>
          </template>
        </div>
      </div>
      <div class="training-detail__desc" v-if="course.course_description">
        <div class="training-detail__sub-head">
          <h3 class="training-detail__subtitle title">{{ $t('lk.training.descriptionTitle') }}</h3>
        </div>
        <div class="training-detail__desc-text" v-html="course.course_description"></div>
      </div>
      <div class="training-detail__links" v-if="course.links && course.links.length">
        <div class="training-detail__sub-head">
          <h3 class="training-detail__subtitle title">{{ $t('lk.training.linksTitle') }}</h3>
        </div>
        <div class="row">
          <div
            v-for="(linkItem, i) in course.links"
            :key="i"
            class="col-lg-6 mb-2 training-detail__links-item"
          >
            <a :href="linkItem.link" class="training-detail__links-link" target="_blank">
              <CIcon name="cil-link" size="sm" class="mr-1 mb-1" />
              {{ linkItem.title }}
            </a>
          </div>
        </div>
      </div>
      <div class="training-detail__accordion" :class="{'_active': activeProgram}" v-if="course.program">
        <div class="training-detail__sub-head _accordion" @click="toggleAccordion()">
          <span class="training-detail__subarrow">
            ❭
          </span>
          <h3 class="training-detail__subtitle title">{{ $t('lk.training.programTitle') }}</h3>
        </div>
        <div class="training-detail__info" v-show-slide:600="activeProgram">
          <LessonAccordionComponent
            :accordion-data="course.program"
          />
        </div>
      </div>
      <div class="training-detail__curators" v-if="course.curator_authors && course.curator_authors.length">
        <div class="training-detail__sub-head">
          <h3 class="training-detail__subtitle title">{{ $t('lk.training.curatorAuthorsTitle') }}</h3>
        </div>
        <div class="swiper" ref="swiper">
          <div class="swiper-wrapper">
            <CuratorAuthor
              v-for="(item, i) in course.curator_authors"
              :author="item"
              :key="i"
              class="swiper-slide training-detail__curator-item"
            ></CuratorAuthor>
          </div>
        </div>
      </div>
      <div class="training-detail__tariffs" v-if="subscribeData.length">
        <div class="training-detail__sub-head">
          <h3 class="training-detail__subtitle title">{{ $t('lk.training.tariffsTitle') }}</h3>
        </div>
        <CSpinner v-if="loadingTariffs || loadingEduTariffsAccess" color="primary" size="sm" />
        <div class="training-detail__tariffs-list" v-else>
          <subscribe-card
            v-for="item in subscribeData"
            :key="item.id"
            :active-tariff-id="item.isTraining ? ((course.isAccess && (!course?.accessConditions || course?.accessConditions.access)) && course.userTariff) || '' : userTariffId"
            :active-tariff="item.isTraining ? ((course.isAccess && (!course?.accessConditions || course?.accessConditions.access)) && (course.userTariff || '') === item.id) || false : userTariffActive"
            :color="item.color"
            :to-date="item.isTraining ? null : getToDate"
            :tariff-id="item.id"
            :title="item.name"
            :description="item.description"
            :secondDesc="item.secondDesc"
            :active-month="item.isTraining ? 0 : activeMonth"
            :month-list="item.isTraining ? item.monthList : item.variants"
            :function-list="item.features"
            :isTraining="item.isTraining"
            :isIndependent="item.isIndependent || false"
            :learnList="item.learnList"
            :dailyProfit="item.dailyProfit"
            :teacherName="item.teacherName"
            :teacherPosition="item.teacherPosition"
            :teacherInfo="item.teacherInfo"
            :price="item.price"
            :isSpecical="item.isSpecical"
            :disabledPay="item.disabledPay"
            :hideSelect="item.hideSelect"
            :hasMentor="course.userHasMentor"
            :currentCurator="course.userCurator"
            :isFree="item.isFree"
            :course = course
            :isAccessByConditions = item.isAccessByConditions
            @pay="prePay"
            @updatePreview="updatePreview"
            class="_inner"
          />
        </div>
      </div>
      <ChangeTariff
        v-if="showExplanation"
        :show-explanation="showExplanation"
        :target-tariff="targetTariff"
        @firstBuyTraining="toggleShowCongratulate"
        @updateShow="updateShow"
        @errorPopup="handleError"
        @updatePreview="updatePreview"
      />
      <CModal
        :title="$t('lk.subscribe.modal.tariffChange')"
        centered
        :show="showPopupError"
        @update:show="showPopupError = $event"
      >
        <template v-if="paymentUrl">
          <p>{{ $t("lk.subscribe.modal.pageNoOpen") }}</p>
          <CLink
            class="text-primary"
            target="_blank"
            :href="paymentUrl"
            @click="showPopupError = false"
          >
            {{ paymentUrl }}
          </CLink>
        </template>
        <template v-else>
          <p>{{ errorMessage }}</p>
        </template>
        <template v-slot:footer>
          <div class="w-100">
            <CButton color="dark" @click="showPopupError = false">
              {{ $t("lk.subscribe.modal.cancel") }}
            </CButton>
          </div>
        </template>
      </CModal>
    </div>

    <PrebuyModal />
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import LessonAccordionComponent from '@/components/training/LessonAccordionComponent';
import SubscribeCard from '@/components/subscribe/SubscribeCard';
import ChangeTariff from '@/components/subscribe/ChangeTariff';
import SmallAuthor from '@/components/training/SmallAuthor';
import CuratorAuthor from '@/components/training/CuratorAuthor';
import Author from '@/assets/js/Author.class';
import Swiper from 'swiper';
import {BUILD_PUBLIC_PATH} from '@/assets/js/constants';
import {mapGetters} from 'vuex';
import PrebuyModal from "@/components/subscribe/PrebuyModal.vue";

export default {
  name: 'TrainingDetail',
  components: {
    PrebuyModal,
    LessonAccordionComponent,
    SubscribeCard,
    ChangeTariff,
    SmallAuthor,
    CuratorAuthor,
  },
  data() {
    return {
      BUILD_PUBLIC_PATH: BUILD_PUBLIC_PATH,
      baseUrl: axios.defaults.baseURL,
      loading: false,
      activeProgram: false,
      course: null,
      activeMonth: 1,
      targetTariff: {
        tariff_id: null,
        tariff_label: null,
        month_count: null,
        color: null,
        isTraining: false,
        isIndependent: false,
        curator: null,
      },
      showExplanation: false,
      showPopupError: false,
      paymentUrl: "",
      errorMessage: "",
      showCongratulate: false,
      loadingEduTariffsAccess: false,
      loadingTariffs: false,
      subscribeTariningData: [
        {
          id: "standard_edu",
          active: true,
          color: "#3686F6",
          name: { ru: "Standard", en: "Standard" },
          hideSelect: true,
          price: this.eduPrice(1500, 'standard_edu'),
          isTraining: true,
          monthList: [{
            monthsCount: 0,
            name: {
              en: "",
              ru: "",
            },
            oldPrice: this.eduOldPrice(1500, 'standard_edu')
          }],
          description: {
            ru: "Комплексный, практический онлайн-курс по торговле фьючерсами. <br> <br> Все занятия доступны в записи.",
            en: "Comprehensive, practical online course on futures trading. <br><br> All classes are available on the record.",
          },
          secondDesc: {
            ru: "<!--span style='color: #3686F6; margin:0'>Курс состоялся с 03.10 по 14.10</span-->",
            en: "<span style='color: #3686F6; margin:0'>Course in Russian language</span>",
          },
          learnList: this.$t(`lk.subscribe.subscribeTariningData[0].learnList`),
          dailyProfit: "29554$ (15.02.21)",
          teacherName: "Alex Zukutoke",
          teacherPosition: "CEO Cvizor.com",
          teacherInfo: this.$t(
            `lk.subscribe.subscribeTariningData[0].teacherInfo`
          ),
          cardList: this.$t(`lk.subscribe.subscribeTariningData[0].cardList`),
          features: [
            {
              ru: "30+ часов видео-материалов с таймкодами",
              en: "30+ hours of online classes with timecodes",
            },
            {
              ru: "Общий чат всех учащихся для совместной работы",
              en: "is random or otherwise generated",
            },
            this.bonusProAlertsStandardDesc(),
            {
              ru: "Выполнение и проверка домашних заданий",
              en: "Completing and checking homework assignments",
            },
            {
              ru: "Трёхмесячный доступ к записям занятий курса на собственной платформе",
              en: "Three-month access to course recordings on your own platform",
            },
            {
              ru: "Доступ к закрытому модулю Тренды в Cryptovizor",
              en: "Access to the closed Trends module in Cryptovizor",
            },
            {
              ru: "Возможность стать участником команды дейтрейдеров",
              en: "The opportunity to become a member of the day-traders team",
            },
          ],
        },
        {
          id: "all_in_edu",
          active: true,
          color: "#FCB81C",
          price: this.eduPrice(1500, 'all_in_edu'),
          name: { ru: "ALL-IN", en: "ALL-IN" },
          hideSelect: true,
          isTraining: true,
          isSpecical: true,
          monthList: [{
            monthsCount: 0,
            name: {
              en: "",
              ru: "",
            },
            oldPrice: this.eduOldPrice(1500, 'all_in_edu')
          }],
          description: {
            ru: "Доступно после успешного прохождения программы STANDARD. Предусмотрена работа в мини-группах, по уникальной методике протестированной 10+ группами выпускников.",
            en: "Available after successful completion of the STANDARD program. Work in mini-groups is provided, according to a unique methodology tested by 10+ groups of graduates.",
          },

          secondDesc: {
            // ru: "<span  style='color: #3686F6; margin:0'>Старт курса: 03.10.2022</span><br><span style='color: #FCB81C; margin:0'>Наставничество до 15.02.2023</span>",
            // en: "<span  style='color: #3686F6; margin:0'>Course start: 03.10.2022</span><br><span style='color: #FCB81C; margin:0'>Mentoring up to 15.02.2023</span>",
          },

          learnList: this.$t(`lk.subscribe.subscribeTariningData[1].learnList`),
          dailyProfit: "29554$ (15.02.21)",
          teacherName: "Alex Zukutoke",
          teacherPosition: "CEO Cvizor.com",
          teacherInfo: this.$t(
            `lk.subscribe.subscribeTariningData[1].teacherInfo`
          ),
          cardList: this.$t(`lk.subscribe.subscribeTariningData[1].cardList`),
          features: [
            {
              ru: "15 недельное практическое сопровождение с куратором",
              en: "15-week practical support with a mentor",
            },
            {
              ru: "Еженедельные аудио встречи в рамках малой группы для анализа сделок",
              en: "Weekly audio meetings within a small group for the analysis of transactions",
            },
            {
              ru: "Регулярная отработка элементов трейдинга для формирования собственной торговой системы",
              en: "Regular development of trading elements for the formation of your own trading system",
            },
            {
              ru: "4х недельный трейдинг марафон под руководством куратора",
              en: "4-week trading marathon under the guidance of a mentor",
            },
            {
              ru: "Персональный разбор сделок",
              en: "Personal analysis of transactions",
            },
            {
              ru: "6 мастер-классов от кураторов All-in",
              en: "6 master-classes from mentors of All-in",
            },
            {
              ru: "Подписка PRO+ALERTS на 3 месяца",
              en: "PRO+ALERTS subscription for 3 months"
            },
          ],
        },
        {
          id: "mentorship_edu",
          active: true,
          color: "#4DBD74",
          price: this.eduPrice(700, 'mentorship_edu'),
          name: { ru: "Mentorship", en: "Mentorship" },
          hideSelect: false,
          isTraining: true,
          isSpecical: true,
          monthList: [{
            monthsCount: 0,
            name: {
              en: "",
              ru: "",
            },
            oldPrice: this.eduOldPrice(700, 'mentorship_edu')
          }],
          description: {
            ru: "Менторство доступно ученикам с пакетом Standard. Студент может участвовать в программе All-in и одновременно воспользоваться менторством для детальной проработки конкретных задач.",
            en: "Mentoring is available to students with the Standard package. The student can participate in the All-in program and at the same time take advantage of mentoring to work through specific tasks in detail.",
          },

          secondDesc: {
            ru: "",
            en: "",
          },

          learnList: this.$t(`lk.subscribe.subscribeTariningData[1].learnList`),
          dailyProfit: "29554$ (15.02.21)",
          teacherName: "Alex Zukutoke",
          teacherPosition: "CEO Cvizor.com",
          teacherInfo: this.$t(
            `lk.subscribe.subscribeTariningData[1].teacherInfo`
          ),
          cardList: this.$t(`lk.subscribe.subscribeTariningData[1].cardList`),
          features: [
            {
              ru: "4 онлайн-консультации, суммарное время которых составляет 240 минут",
              en: "4 online consultations with a total time of 240 minutes",
            },
            {
              ru: "Анализ сделок в дневнике трейдера с наставником",
              en: "Analysis of trades in the trader's diary with a mentor",
            },
            {
              ru: "Поиск и исправление ключевых торговых ошибок ученика профессиональным трейдером",
              en: "Finding and correcting key trading mistakes of a student by a professional trader",
            },
            {
              ru: "Еженедельные задания по торговле и их проверка",
              en: "Weekly trade assignments and their verification",
            },
            {
              ru: "Формирование прибыльной торговой системы",
              en: "Creating a profitable trading system",
            },
          ],
        },
        {
          id: "web3_edu",
          active: true,
          color: "#f66936",
          name: { ru: "WEB3", en: "WEB3" },
          price: this.eduPrice(600, 'web3_edu'),
          isFree: true,
          isTraining: true,
          isIndependent: true,
          hideSelect: true,
          isSpecical: true,
          isAccessByConditions: true,
          monthList: [{
            monthsCount: 0,
            name: {
              en: "",
              ru: "",
            },
            oldPrice: this.eduOldPrice(600, 'web3_edu')
          }],
          description: {
            ru: "Практический курс погружения в Web3. Ретродропы, мультиакинг, поиск и ресерч альфа проектов, Ordinals и Brc-20, DEX, торговля NFT, дропхантинг.<br><br>Онлайн доступ к записям уроков и будущим занятиям.",
            en: "A hands-on immersion course into Web3. Retrodrops, multi-accing, searching and researching alpha projects, Ordinals and Brc-20, DEX, NFT trading, drophunting.<br><br>Online access to recorded lessons and future sessions.",
          },
          secondDesc: {
            ru: "",
            en: "",
          },
          learnList: this.$t(`lk.subscribe.subscribeTariningData[0].learnList`),
          dailyProfit: "29554$ (15.02.21)",
          teacherName: "Alex Zukutoke",
          teacherPosition: "CEO Cvizor.com",
          teacherInfo: this.$t(
            `lk.subscribe.subscribeTariningData[0].teacherInfo`
          ),
          cardList: this.$t(`lk.subscribe.subscribeTariningData[0].cardList`),
          features: [
            {
              ru: "10 часов видео уроков, разбитых на 12 уровней",
              en: "10 hours of video lessons, divided into 12 levels",
            },
            {
              ru: "5 квестов по закреплению практических навыков",
              en: "5 quests to reinforce practical skills",
            },
            {
              ru: "Ценные NFT и POAP в награду за прохождение",
              en: "Valuable NFTs and POAP as rewards for completion"
            },
            /*{
              ru: "Доступ в закрытую группу Web3 сообщества Tradium",
              en: "Access to the private Web3 community group of Tradium",
            },*/
            {
              ru: "Доступ к эксклюзивному контенту сообщества: стримы, гайды, инструкции и лайфхаки",
              en: "Access to the community's exclusive content: streams, guides, instructions, and life hacks",
            },
            /*{
              ru: "Доступ к закрытым эвентам и бесплатным минтам от сообщества",
              en: "Access to private events and free mints from the community",
            },*/
            {
              ru: "Трёхмесячный доступ к записям занятий",
              en: "Three-month access to recorded sessions",
            },
            /*{
              ru: "Оплата реферальными бонусами Cvizor",
              en: "Payment with Cvizor referral bonuses",
            },*/
          ],
        },
        {
          id: "crypto_basics_edu",
          active: true,
          color: "#f66936",
          name: { ru: "Crypto Basics", en: "Crypto Basics" },
          price: this.eduPrice(600, 'crypto_basics_edu'),
          isFree: true,
          isTraining: true,
          isIndependent: true,
          hideSelect: true,
          isSpecical: true,
          isAccessByConditions: true,
          monthList: [{
            monthsCount: 0,
            name: {
              en: "",
              ru: "",
            },
            oldPrice: this.eduOldPrice(600, 'crypto_basics_edu')
          }],
          description: {
            ru: "Бесплатный курс для новичков, который поможет понять основы криптотрейдинга и безопасного использования криптовалютных сервисов.<br><br>Подходит для тех, кто только начинает путь в криптовалютный мир и хочет избежать типичных ошибок.",
            en: "A free course for beginners that will help you understand the basics of crypto trading and the safe use of cryptocurrency services.<br><br>Suitable for those who are just starting their journey into the world of cryptocurrency and want to avoid common mistakes.",
          },
          secondDesc: {
            ru: "",
            en: "",
          },
          learnList: this.$t(`lk.subscribe.subscribeTariningData[0].learnList`),
          dailyProfit: "29554$ (15.02.21)",
          teacherName: "Alex Zukutoke",
          teacherPosition: "CEO Cvizor.com",
          teacherInfo: this.$t(
            `lk.subscribe.subscribeTariningData[0].teacherInfo`
          ),
          cardList: this.$t(`lk.subscribe.subscribeTariningData[0].cardList`),
          features: [
            {
              ru: "9 видео-уроков по самым главным темам криптотрейдинга",
              en: "9 video lessons on the most important topics in crypto trading",
            },
            {
              ru: "Инструкции по работе с биржами",
              en: "Instructions on working with exchanges",
            },
            {
              ru: "Гайды по использованию сервисов для трейдинга",
              en: "Guides on using trading services"
            },
            {
              ru: "Общий чат студентов, где можно задавать вопросы",
              en: "General student chat for asking questions",
            },
            {
              ru: "Неограниченный доступ к записям занятий для подписчиков наших Telegram каналов",
              en: "Unlimited access to lesson recordings for subscribers to our Telegram channels",
            },
          ],
        },
      ],
      subscribeData: [],
    }
  },
  computed: {
    ...mapGetters({
      isGuest: 'user/isGuest'
    }),
    getEvents() {
      return this.$store.state.user.userData?.events_users?.[0];
    },
    userTariffActive() {
      return this.$store.state.user.userData.tariffIsActive || false;
    },
    userTariffId() {
      if (!this.userTariffActive) return "";
      return this.$store.state.user.userData.tariff.id;
    },
    getToDate() {
      const date = this.$store.state.user.userData.tariffExpired;
      if (!date) return "";

      const dateFormat = new moment(date);
      if (!dateFormat.isValid()) return "";

      return ` ${this.$t("lk.subscribe.toDate")} ${dateFormat.format(
        "DD.MM.YY"
      )}`;
    },
    locale() {
      return this.$root.$i18n.locale;
    },
  },
  created() {
    this.getPreviewData()

    if (!this.userTariffActive && !this.$store.state.user.userData.tariff.id) {
      this.activeMonth = 12;
    }
  },
  methods: {
    toggleAccordion() {
      this.activeProgram = !this.activeProgram;
    },
    updatePreview() {
      this.disabledPay(this.course.id, false)
    },
    getPreviewData() {
      this.loading = true;

      axios.get(`${this.baseUrl}/api/v2/courses/${this.$route.params.slug}`, {
        params: {
          with: ['authors', 'curator_authors']
        },
      })
        .then(res => {
          this.course = res?.data?.data;
          if(this.course.authors){
            this.course.authors = this.course.authors.map(item => new Author(item))
          }
          if(this.course.curator_authors){
            this.course.curator_authors = this.course.curator_authors.map(item => new Author(item))
          }
          this.getTariffs()
          this.disabledPay(this.course.id)
          this.initEdu(this.course.id);
          this.$nextTick(() => {
            if (this.$refs.swiper) {
              this.swiper = new Swiper(this.$refs.swiper, {
                slidesPerView: 'auto',
                observer: true,
                observeParents: true,
                observeSlideChildren: true,
                on: {
                  init(swiper) {
                    setTimeout(() => {
                      try {
                        swiper.update()
                      } catch (e) {
                        console.error(e)
                      }
                    }, 300)
                  }
                }
              })
            }
          })
        })
        .catch(() => {
          this.$toastr.error(this.$t('lk.training.errorLoadData'))
        })
        .finally(() => {
          this.loading = false
        })
    },
    toggleShowCongratulate(){
      if(this.targetTariff.tariff_id === 'standard_edu') this.showCongratulate = true
    },
    updateShow(showStatus) {
      this.showExplanation = showStatus;
    },
    backHandler() {
      this.$router.go(-1);
    },
    handleError(error) {
      this.showPopupError = true;
      if (error.type === "block") {
        this.paymentUrl = error.message;
      } else {
        this.errorMessage = error.message;
      }
    },
    async disabledPay(courseId, setLoading = true) {
      if(this.isGuest){
        return
      }
      if(setLoading){
        this.loadingEduTariffsAccess = true
      }
      Promise.all(this.subscribeData.map(item => axios.post(`api/v2/events/${courseId}/check-tariff-access`,{
        tariff: item.id
      }))).then((responses) => {
        responses.forEach((item,index) => {
          if(item.status < 300){
            this.subscribeData[index].disabledPay = !item.data.access
          }else{
            this.subscribeData[index].disabledPay = true
          }
        })
      }).finally(()=>{
        if(setLoading){
          this.loadingEduTariffsAccess = false
        }
      })
    },
    prePay(obj = {}) {
      this.targetTariff.courseId = this.course.id
      this.targetTariff.month_count = obj.month_count;
      this.targetTariff.tariff_id = obj.tariff_id;
      this.targetTariff.color = obj.color;
      this.targetTariff.title = obj.title;
      this.targetTariff.isTraining = obj.isTraining;
      this.targetTariff.isIndependent = obj.isIndependent || false;
      this.targetTariff.tariff_label = obj.title && obj.title[this.locale] || null;
      if(this.targetTariff.tariff_label){
        this.targetTariff.tariff_label = this.targetTariff.tariff_label.toUpperCase()
      }
      this.targetTariff.curator = obj.curator;
      this.updateShow(true);
    },
    initEdu(courseId) {
      if(this.isGuest){
        this.subscribeTariningData
          .filter(item => item.id === 'mentorship_edu')
          .forEach(tariff => {
            tariff.monthList = [
              {
                name: {ru: 'Автоназначить', en: 'Auto assign'},
                monthsCount: 'auto',
              }
            ]
          })
        return
      }
      this.loadingTariffs = true;

      axios.get('api/v1/curators-public', {
        params: {
          tariff: 'mentorship_edu',
          event_id: courseId
        }
      })
        .then(({ data }) => {
          this.subscribeTariningData
            .filter(item => item.id === 'mentorship_edu')
            .forEach(tariff => {
              tariff.monthList = [
                {
                  name: {ru: 'Автоназначить', en: 'Auto assign'},
                  monthsCount: 'auto',
                }
              ]
              tariff.monthList.push(...data.data.filter(variant => variant.tg_nick || variant.name).map(variant => {
                return {
                  name: {ru: variant.name, en: variant.name},
                  monthsCount: variant.id,
                  tg_nick: variant.tg_nick,
                }
              }))
            })
        }).finally(()=>{
          this.loadingTariffs = false;
        })
    },
    getTariffs() {
      if (this.course?.tariffs?.length) {
        this.subscribeTariningData.forEach(item => {
          this.course.tariffs.forEach(elem => {
            if (elem != item.id) return;

            const time = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            if (!this.course.availableToBuyFrom || (time > this.course.availableToBuyFrom)) {
              this.subscribeData.push(item);
            }
          })
        });
      }
    },

    eduPrice(basePrice, key){
      const k = 'price_' + key
      if(this.$store.state.user.userData.promoSummary?.[k]?.minus){
        return basePrice - this.$store.state.user.userData.promoSummary[k].minus
      }
      return basePrice
    },
    eduOldPrice(basePrice, key) {
      const k = 'price_' + key
      if(this.$store.state.user.userData.promoSummary?.[k]?.minus){
        return basePrice
      }
      return 0
    },

    // for promotions
    web3Price(basePrice) {
      if(this.$store.state.user.userData.promoSummary?.price_web3?.minus){
        return basePrice - this.$store.state.user.userData.promoSummary.price_web3.minus
      }
      return basePrice
    },
    web3OldPrice(basePrice) {
      if(this.$store.state.user.userData.promoSummary?.price_web3?.minus){
        return basePrice
      }
      return 0
    },
    bonusProAlertsStandardDesc()
    {
      if(this.$store.state.user.userData.promoSummary?.bonus_standard_pro_alerts?.plus){
        return {
          ru: "Подписка PRO+ALERTS на 3+6 месяцев",
          en: "PRO+ALERTS subscription for 3 months"
        }
      }
      return {
        ru: "Подписка PRO+ALERTS на 3 месяца",
        en: "PRO+ALERTS subscription for 3 months"
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.training-detail {
  margin-bottom: -3rem;
  &__picture {
    position: relative;
    overflow: hidden;
    width: calc(100% + 2.5rem);
    height: 16.5rem;
    margin: -1.25rem -1.25rem 0;
    @include media-breakpoint-up(md) {
      height: 22.25rem;
      width: calc(100% + 4rem);
      margin: -1.75rem -2rem 0;
    }
    @include media-breakpoint-up(xl) {
      height: 31.25rem;
      width: calc(100% + 5rem);
      margin: -2.25rem -2.5rem 0;
    }
    .btn {
      position: absolute;
      top: 1rem;
      z-index: 1;
      left: 2.5rem;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
    &-content {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      z-index: 1;
      padding: 2.875rem 1.25rem 1.25rem;
      background: linear-gradient(
        180deg, rgba(0, 0, 0, 0.00) 0%,
        rgba(0, 0, 0, 0.01) 3.08%,
        rgba(0, 0, 0, 0.02) 6.16%,
        rgba(0, 0, 0, 0.05) 9.24%,
        rgba(0, 0, 0, 0.09) 12.31%,
        rgba(0, 0, 0, 0.15) 15.39%,
        rgba(0, 0, 0, 0.21) 18.47%,
        rgba(0, 0, 0, 0.28) 21.55%,
        rgba(0, 0, 0, 0.36) 24.63%,
        rgba(0, 0, 0, 0.43) 27.71%,
        rgba(0, 0, 0, 0.49) 30.78%,
        rgba(0, 0, 0, 0.55) 33.86%,
        rgba(0, 0, 0, 0.59) 36.94%,
        rgba(0, 0, 0, 0.62) 40.02%,
        rgba(0, 0, 0, 0.63) 43.1%,
        rgba(0, 0, 0, 0.64) 46.18%
      );
      @include media-breakpoint-up(md) {
        padding: 5.375rem 2rem 2rem;
      }
      @include media-breakpoint-up(xl) {
        padding: 5.375rem 2.5rem 2rem;
      }
    }
  }
  &__picture,
  &__desc,
  &__accordion,
  &__tariffs {
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 2.5rem;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 3rem;
    }
  }
  &__title {
    font-size: 1.375rem;
    flex: 1 1 auto;
    font-weight: 700;
    color: #FFFFFF;
    margin: 0;
    @include media-breakpoint-up(md) {
      font-size: 1.875rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 2.875rem;
    }
  }
  &__title + &__author {
    margin-top: 1.25rem;
    @include media-breakpoint-up(xl) {
      margin-top: 1.5rem;
    }
  }
  &__sub-head {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 1.25rem;
    }
    &._accordion {
      cursor: pointer;
    }
  }
  &__subtitle {
    margin: 0;
    font-weight: 700;
    order: 1;
    font-size: 1.375rem;
    @include media-breakpoint-up(md) {
      font-size: 1.53125rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.53125rem;
    }
  }
  &__subarrow + &__subtitle {
    padding-right: 0.75rem;
  }
  &__subarrow {
    cursor: pointer;
    transform: rotate(90deg);
    font-weight: 700;
    transition: 0.5s;
    position: relative;
    top: 0.125rem;
    height: 20px;
    font-size: 1rem;
    line-height: 1.25rem;
    width: 20px;
    order: 2;
    text-align: center;
    flex: none;
    display: block;
    ._active & {
      transform: rotate(-90deg);
    }
  }
  &__tariffs {
    &-list {
      display: flex;
      flex-direction: column;
      gap: 0.625rem 0;
    }
  }
  &__info {
    .accordion-study {
      margin: 0;
    }
  }

  &__desc-text {
    /deep/ {
      audio,
      iframe,
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  &__links{
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 2.5rem;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 3rem;
    }

    &-link{
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__curators{
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 2.5rem;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 3rem;
    }
  }

  .swiper {
    overflow: visible;
    width: auto;
    margin: 0 -0.375rem;
    @include media-breakpoint-up(md) {
      margin: 0 -0.625rem;
    }
    @include media-breakpoint-up(xl) {
      margin: 0 -0.75rem;
    }
    &-wrapper {
      height: auto;
    }
    &-slide {
      height: auto;
      display: flex;
      flex: none;
    }
  }
  &__curator-item {
    margin: 0 10px;
  }
}
</style>
