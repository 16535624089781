export const prebuyData = {
  offer: {
    tag: 'Вы экономите $500!',
    title: 'Курс Crypto Basics + Standart + 1 год PRO+ALERTS',
    text: 'Получите полный доступ ко всем материалам курса Standart на 1 год, включая Crypto Basics, и подписку PRO+ALERTS на 1 год',
    price: '$1499',
    priceOld: '$2000',
    id: 'group3',
    list: [
      {
        title: 'Crypto Basics + полный доступ к Standart на 1 год',
        text: '',
      },
      {
        title: 'Подписка Pro+Alerts на 1 год включена',
        text: '',
      },
      {
        title: 'Экономия $500',
        text: 'По сравнению с полной стоимостью продуктов',
      },
      {
        title: '',
        text: 'Идеальное предложение для тех, кто хочет начать свой путь в трейдинге с максимальной отдачей и минимальными затратами',
      },
    ],
  },
  allin: {
    tag: 'Вы экономите $1500!',
    title: 'All-in + 6 месяцев PRO+ALERTS за $1499',
    text: 'Получите полный доступ ко всем материалам курса Standart на 6 месяцев, включая All-in, и подписку PRO+ALERTS на 6 месяцев',
    price: '$1499',
    priceOld: '$3000',
    id: 'group2',
    list: [
      {
        title: '6 месяцев доступ ко всем материалам курса Standard и All-in',
        text: '',
      },
      {
        title: '6 месяцев подписки PRO+ALERTS',
        text: 'идеальный инструмент для трейдера',
      },
      {
        title: 'Присоединяйтесь к программе All-in и развивайтесь с поддержкой группы',
        text: '',
      },
      {
        title: 'Экономия $1500',
        text: 'Невероятная выгода!',
      },
      {
        title: '',
        text: `Отличное предложение для тех, кто уже освоил базовые техники и хочет улучшить свою торговлю`,
        twoCols: true,
      },
    ],
  },
  new: {
    tag: 'Вы экономите $1000!',
    title: 'Standart + All-in + 1 год PRO+ALERTS за $2499',
    text: 'Получите полный доступ ко всем материалам курса на 1 год. В комплект входит курс Standart и программа All-in, а так же 1 год подписки PRO+ALERTS',
    price: '$2499',
    priceOld: '$3499',
    id: 'group1',
    list: [
      {
        title: 'Полный комплект обучения',
        text: 'Standart + All-in — обновленные программы',
      },
      {
        title: 'Доступ на 1 год',
        text: 'ко всем материалам — изучайте в удобном для вас темпе',
      },
      {
        title: 'Подписка PRO+ALERTS на год',
        text: 'включена, чтобы вы могли использовать  все инструменты Cryptovizor',
      },
      {
        title: 'Экономия $1000',
        text: 'по сравнению с покупкой каждого продукта по отдельности',
      },
      {
        title: '',
        text: 'Идеально для тех, кто уже знаком со стратегией, но хочет обновить знания',
        twoCols: true,
      },
    ],
  },
}
